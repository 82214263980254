/**
 * Provides a summary based on the field and category provided.
 *
 * @param {string} field - The specific field to generate the summary for. Expected values include "sectorEmpresa", "numeroEmpleados", "tipoSociedad", "decisionesColegiadas".
 * @param {string} category - The category to determine the content of the summary. Expected values are "A", "B", or others depending on the field.
 * @returns {string} A summary that is tailored to the given field and category, offering guidance or recommendations based on the specific context.
 */
export const getSummaryByCategory = (field, category) => {
  switch (field) {
    case "sectorEmpresa":
      return category === "A"
        ? "Dado el sector en el que opera, es probable que su empresa participe en actividades que involucran procesos regulados por la Ley Anti Lavado de Dinero (PLD) debido a su naturaleza vulnerable. Realice un análisis detallado de los riesgos específicos asociados con las actividades de su empresa. Esto incluye identificar áreas donde podría existir un mayor riesgo de lavado de dinero o financiamiento al terrorismo."
        : "El sector en el que opera su empresa, al igual que otros, requiere un análisis exhaustivo para entender las regulaciones y normativas aplicables. Esto es fundamental para desarrollar un programa de cumplimiento efectivo que supervise y asegure la mitigación de riesgos en términos de cumplimiento.";

    case "numeroEmpleados":
      if (category === "A") {
        return "Implementar una estructura de gobierno corporativo formal es fundamental para su empresa debido a su tamaño significativo. Un Consejo de Administración bien estructurado puede proporcionar orientación estratégica de alto nivel y supervisar eficazmente las operaciones, asegurando el cumplimiento de los objetivos empresariales a largo plazo. Además, la creación de comités especializados, como el Comité de Auditoría y el Comité de Evaluación y Compensación, permitirá abordar áreas críticas con mayor profundidad y experiencia, garantizando así una gestión integral y eficiente de los recursos y riesgos corporativos.";
      } else if (category === "B") {
        return "Implementar una estructura de gobierno corporativo en empresas con entre 51 y 100 colaboradores fortalece la transparencia y la comunicación interna, estableciendo canales formales para la interacción regular entre la alta dirección y los empleados, y manteniendo a todos alineados con los objetivos estratégicos. La definición de políticas claras para la gestión de conflictos de interés y su comunicación al personal fortalecen la confianza interna y mejoran la reputación ante partes interesadas. Además, mejora la toma de decisiones estratégicas y operativas, haciéndolas más colegiadas y basadas en datos, lo que reduce la dependencia en decisiones individuales.";
      } else {
        return "Se aconseja adoptar un enfoque de gobernanza ágil y adaptativa. En este contexto, las estructuras organizativas son más compactas y flexibles, por lo que un enfoque ágil en la toma de decisiones es crucial. Promover la responsabilidad individual y clarificar los roles y responsabilidades dentro de la empresa fomenta una cultura de toma de decisiones eficiente y alineada con los objetivos estratégicos. Esta adaptabilidad permite a las empresas más pequeñas responder rápidamente a los cambios del mercado y maximizar su capacidad de innovación y crecimiento, manteniendo al mismo tiempo un control efectivo de las operaciones y riesgos.";
      }

    case "tipoSociedad":
      return category === "A"
        ? "Dado que su tipo de sociedad está obligada a ser administrada bajo la figura del Consejo de Administración, es crucial implementar adecuadamente todos los procesos involucrados con las funciones y facultades del mismo. Esto implica establecer un Consejo efectivo que no solo cumpla con los requisitos legales, sino que también aporte valor estratégico a la empresa. Es recomendable definir claramente las responsabilidades y roles de los miembros del Consejo, así como establecer políticas de gobierno corporativo que promuevan la transparencia y la rendición de cuentas. Al hacerlo, se fortalece la estructura de gobierno de la empresa, lo que puede mejorar la toma de decisiones y facilitar la planificación a largo plazo."
        : "Aunque su tipo de sociedad no está obligada a ser administrada bajo un Consejo de Administración, considerar la implementación de un Consejo Consultivo es una buena práctica que puede ofrecer beneficios significativos. Esto implica reconocer formalmente su papel y funciones en el acta constitutiva de la empresa o mediante la creación de un marco reglamentario específico. Un Consejo Consultivo puede proporcionar orientación estratégica y asesoramiento especializado a la dirección ejecutiva, ayudando a mejorar la toma de decisiones y a enfrentar desafíos empresariales de manera más informada. Además, promueve una cultura de aprendizaje y desarrollo continuo dentro de la organización, lo que puede fortalecer la competitividad y la sostenibilidad a largo plazo.";

    case "decisionesColegiadas":
      if (category === "A") {
        return "Si en su empresa ya se toman decisiones de manera colegiada, considerando a todas las partes interesadas, le recomendamos revisar y analizar las buenas prácticas de Gobierno Corporativo del Consejo Coordinador Empresarial. Incorporar estas prácticas puede fortalecer aún más la estructura de gobierno existente, aumentando la transparencia y mejorando la eficiencia operativa. Esto facilitará una toma de decisiones más inclusiva y alineada con los objetivos estratégicos de la organización, promoviendo así un ambiente empresarial más sostenible y adaptable.";
      } else if (category === "B") {
        return "Si en su empresa se toman algunas decisiones de manera colegiada, pero sin una formalización de procesos que guíe estas decisiones inclusivas, es recomendable establecer políticas claras y documentar los procedimientos. Esto asegurará que todas las partes interesadas sean consideradas de manera equitativa en el proceso de toma de decisiones. Al formalizar estos procesos, se fortalece la cohesión y consistencia en las decisiones estratégicas, mejorando así la capacidad de la empresa para gestionar riesgos y aprovechar oportunidades de manera efectiva.";
      } else {
        return "Dado que en su empresa no se toman decisiones de manera colegiada, es crucial reconocer los riesgos significativos asociados con la continuidad y la trascendencia. Se recomienda considerar la implementación de estructuras formales de gobierno corporativo que promuevan la participación de todas las partes interesadas en la toma de decisiones estratégicas. Establecer un Consejo o Comité Directivo puede enriquecer las decisiones empresariales con diversas perspectivas y experiencia, fomentando así la innovación y la adaptabilidad frente a los desafíos del mercado.";
      }

    default:
      return "";
  }
};

const summaries = {
  getSummaryByCategory,
};

export default summaries;
