import { Typography } from "@mui/material";

export const DenounceBenefits = [
   {
      title: "Fomenta la mejora continua",
      description: (
         <Typography>
            Un medio de comunicación seguro y estable puede facilitar la retroalimentación de sus servicios y/o
            productos, permitiendo establecer la mejora continua y asegurando la máxima satisfacción de las partes
            interesadas.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/fondos/image+13.png",
   },
   {
      title: "Detección temprana de problemas",
      description: (
         <Typography>
            Nuestro canal de denuncias permite identificar de manera temprana el 45% de los problemas internos de la
            organización. Lo que brinda la oportunidad de tomar medidas preventivas y correctivas de manera oportuna,
            evitando riesgos y contingencias futuras.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/fondos/9+2.png",
   },
   {
      title: "Reducción de riesgos y contingencias legales",
      description: (
         <Typography>
            Esta implementación puede reducir de manera significativa los riesgos vinculados al incumplimiento de las
            obligaciones, evitando sanciones de alto impacto para la organización y afectar su reputación de manera
            perjudicial.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/fondos/image+12.png",
   },
   {
      title: "Fomenta una sólida reputación empresarial",
      description: (
         <Typography>
            Implementar y mantener un canal de denuncias permite crear una buena reputación ante competidores y otras
            organizaciones, ya que en la actualidad, las relaciones comerciales, se ven influenciadas mayormente, por
            aspectos cualitativos.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/fondos/image+11.png",
   },
];

export const GovernanceBenefits = [
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/iconos/ICONOS~4.SVG)",
      title: "Seguridad total para su información confidencial",
      description: (
         <Typography>
            Garantizamos la protección constante de sus datos sensibles mediante sistemas de acceso adaptados a cada
            usuario, cumpliendo rigurosos estándares de seguridad de la información.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/carrousel/lecosy3+1.png",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/iconos/Iconos+BENEFICIOS+GOBIERNO+CORPORATIVO_Promoci%C3%B3n+del+intercambio+de+conocimiento.svg)",
      title: "Promoción del intercambio de conocimiento",
      description: (
         <Typography>
            Impulsamos estrategias para adquirir, crear y compartir conocimiento entre consejeros y miembros de su
            organización. Esto potencia el rendimiento del equipo, aprovecha la experiencia existente y fomenta una
            cultura de aprendizaje constante.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/fondos/image+13.png",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/iconos/Iconos+BENEFICIOS+GOBIERNO+CORPORATIVO_Evaluaci%C3%B3n+y+mejora+del+desempe%C3%B1o.svg)",
      title: "Evaluación y mejora del desempeño",
      description: (
         <Typography>
            Proporcionamos una medición detallada del desempeño de los órganos de gobierno y sus miembros, identificando
            oportunidades de mejora a través de informes exhaustivos para una evolución constante en su estructura de
            gobierno.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/carrousel/image+12.png",
   },
   {
      icon: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/iconos/Iconos+BENEFICIOS+GOBIERNO+CORPORATIVO_Optimizaci%C3%B3n+de+la+gesti%C3%B3n+de+la+informaci%C3%B3n.svg)",
      title: "Optimización de la gestión de la información",
      description: (
         <Typography>
            Simplificamos la organización, búsqueda y seguridad de documentos clave, ofreciendo visibilidad total de sus
            recursos. Esto le permite tomar decisiones respaldadas por datos precisos, ahorrando tiempo y reforzando la
            eficiencia operativa.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/carrousel/image+11.png",
   },
];
export const BeneficialOwnerBenefits = [
   {
      title: "Reducción de riesgos",
      description: (
         <Typography>
          Evite sanciones relacionadas con los artículos 84-M y 84-N del Código Fiscal Federal de la Federación al mantener una base de datos integral y actualizada de los beneficiarios controladores.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/section-8-1.png",
   },
   {
      title: "Respuesta rápida ante requerimientos de la autoridad",
      description: (
         <Typography>
           Obtenga los mecanismos necesarios para cumplir con las obligaciones legales y tributarias de control de información de beneficiarios, controladores y de socios, accionistas correspondientes.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/section-8-2.png",
   },
   {
      title: "Identificación objetiva de los beneficiarios controladores",
      description: (
         <Typography>
           Reciba formación especializada para identificar correctamente a los beneficiarios controladores y asegurar la efectividad de sus controles, evitando posibles sanciones.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/beneficiarioControlador/section8-3.png",
   },
  
];

export const ComplianceProgramBenefits = [
   {
      title: "Implementar una sólida gestión empresarial",
      description: (
         <Typography>
            Las organizaciones pueden alcanzar su máximo potencial al operar de manera armoniosa y sistemática. Los
            beneficios resultantes son tangibles a corto plazo, repercutiendo positivamente en resultados
            organizacionales y económicos.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/programa-cumplimiento/carrousel/image+9.png",
   },
   {
      title: "Crear una cultura de cumplimiento",
      description: (
         <Typography>
            Contar con disposiciones y lineamientos claros, las partes interesadas actuarán en armonía, garantizando el
            cumplimiento de sus objetivos de manera segura. Estos mecanismos permiten desempeñar las funciones de manera
            más efectiva, lo que les brinda certeza al desempeño de cada una de las áreas.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/programa-cumplimiento/carrousel/36+1.png",
   },
   {
      title: "Generar confianza frente a terceros",
      description: (
         <Typography>
            El compromiso con el programa de cumplimiento contribuye a generar confianza a clientes, proveedores,
            asociados, prestadores de servicios, partes contractuales, instituciones de crédito y entidades públicas,
            entre otros, influyendo en decisiones comerciales en las que se encuentre involucrada.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/programa-cumplimiento/carrousel/image+12.png",
   },
   {
      title: "Contribuir de manera positiva al crecimiento interno",
      description: (
         <Typography>
            Facilitar el flujo efectivo de indicaciones e información de los procesos, mejora la eficacia y aporta
            dinamismo a los resultados positivos, ya que las áreas responsables del cumplimiento son guiadas de manera
            correcta en concordancia con la gestión empresarial y la legislación aplicable.
         </Typography>
      ),
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/programa-cumplimiento/carrousel/image+14.png",
   },
];
