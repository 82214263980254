import "./index.css";
import {
  HomeScreen,
  ContactScreen,
  AboutUsScreen,
  ServiceScreen,
  PrivacyScreen,
  DenounceScreen,
  GovernanceCorpScreen,
  LandingPageScreen,
  GovernanceFormScreen,
  ResultsScreen,
  BeneficialOwner
} from "./pages";
import { GovernanceTestModal } from "./components/LandingPage/GovernanceTestModal.js";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import { EmailProvider } from "./components/LandingPage/EmailContext.jsx";

function App() {
  const [open, setOpen] = useState(true);
  const location = useLocation();

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/contacto" element={<ContactScreen />} />
        <Route path="/nosotros" element={<AboutUsScreen />} />
        <Route path="/soluciones" element={<ServiceScreen />} />
        <Route path="/canal-denuncias" element={<DenounceScreen />} />
        {/*<Route path="/programa-cumplimiento" element={<ComplianceProgramScreen />} />*/}
        <Route
          path="/gobierno-corporativo"
          element={<GovernanceCorpScreen />}
        />
        <Route path="/Aviso-de-privacidad" element={<PrivacyScreen />} />
        <Route path="/landing-page" element={<LandingPageScreen />} />
        <Route path="/landing-page/form" element={<GovernanceFormScreen />} />
        <Route path="/landing-page/form/results" element={<ResultsScreen />} />
        <Route path="/beneficiario-controlador" element={<BeneficialOwner/>}/>
        <Route
          path="/sesion"
          element={<Navigate to="http://localhost:3003" />}
        />
      </Routes>
      {location.pathname === "/" && (
        <GovernanceTestModal open={open} handleClose={handleClose} />
      )}
    </>
  );
}

function WrappedApp() {
  return (
    <BrowserRouter>
      <EmailProvider>
        <App />
      </EmailProvider>
    </BrowserRouter>
  );
}

export default WrappedApp;
