import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
   palette: {
      mode: "light",
      primary: {
         main: "#162c44",
      },
      secondary: {
         main: "#46566a",
      },
   },
   components: {
      MuiTextField: {
         styleOverrides: {
            root: {
               outlineColor: "transparent",
               outline: 0,
               "& .MuiOutlinedInput-root": {
                  backgroundColor: "#e8ecef",
                  borderRadius: 15,
                  "& fieldset": {
                     borderRadius: 15,
                     border: 0,
                  },
               },
            },
         },
      },
      MuiButton: {
         styleOverrides: {
            root: {
               textTransform: "none",
               fontFamily: "Bold",
               backgroundColor: "#162c44",
               color: "white",
               "&:hover": {
                  backgroundColor: "#162c44",
               },
            },
         },
      },
   },
});

export const TextFieldThemeConfig = ({ children }) => {
   return (
      <ThemeProvider theme={theme}>
         <CssBaseline />
         {children}
      </ThemeProvider>
   );
};
