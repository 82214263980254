import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeConfig } from "./config/theme.config";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <React.StrictMode>
      <div className="container">
         <ThemeConfig>
            <App />
         </ThemeConfig>
      </div>
   </React.StrictMode>
);
