import * as React from "react";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
  Button,
  Toolbar,
  Collapse,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material/";
import MenuIcon from "@mui/icons-material/Menu";
import { NavBarThemeConfig } from "../config/navBarTheme.config";
import { NavLink, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PropTypes from "prop-types";

const drawerWidth = 240;
const pages = ["Inicio", "Conózcanos"];

export const NavBar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openServices, setOpenServices] = React.useState(false);
  const [hoverComponent, sethoverComponent] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigate = useNavigate();
  let activeStyle = {
    textDecoration: "none",
    color: "#46566a",
  };

  let unactiveStyle = {
    textDecoration: "none",
    color: "#162c44",
  };

  function page(item) {
    switch (item) {
      case "Contacto":
        return "/contacto";
      case "Inicio":
        return "/";
      case "Conózcanos":
        return "/nosotros";
      default:
        return "/";
    }
  }

  const drawer = (
    <Box sx={{ textAlign: "left" }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          component={"img"}
          alt="Logo"
          src={
            "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icono-lecosy.png"
          }
          sx={{ display: "flex", height: 50, my: 1 }}
        />
      </Box>
      <Divider />
      <List>
        {pages.map((item) => (
          <ListItem key={item} disablePadding>
            <Box onClick={handleDrawerToggle} sx={{ width: "100%" }}>
              <NavLink
                to={page(item)}
                style={({ isActive }) =>
                  isActive ? activeStyle : unactiveStyle
                }
              >
                <ListItemButton sx={{ textAlign: "left" }}>
                  <Typography
                    sx={{
                      fontSize: "19px",
                      py: 1,
                      textTransform: "none",
                      fontFamily: "Bold",
                    }}
                  >
                    {item}
                  </Typography>
                </ListItemButton>
              </NavLink>
            </Box>
          </ListItem>
        ))}
      </List>
      <List>
        <ListItem
          disablePadding
          sx={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <ListItemButton
            onClick={() => setOpenServices(!openServices)}
            sx={{ color: "#162c44" }}
          >
            <Typography
              sx={{
                fontSize: "19px",
                py: 1,
                textTransform: "none",
                fontFamily: "Bold",
              }}
            >
              Soluciones
            </Typography>
            {!openServices ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </ListItemButton>
        </ListItem>
      </List>
      <Collapse in={openServices}>
        <NavLink
          onClick={() => {
            setOpenServices(false);
            handleDrawerToggle();
          }}
          to={"/canal-denuncias"}
          style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText
                style={{ color: "#162c44" }}
                primary={"Canal de denuncias"}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={() => {
            setOpenServices(false);
            handleDrawerToggle();
          }}
          to={"/gobierno-corporativo"}
          style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText
                style={{ color: "#162c44" }}
                primary={"Gobierno corporativo"}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          onClick={() => {
            setOpenServices(false);
            handleDrawerToggle();
          }}
          to={"/beneficiario-controlador"}
          style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText
                style={{ color: "#162c44" }}
                primary={"Beneficiario controlador"}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
       
        {/*<NavLink
               onClick={() => {
                  setOpenServices(false);
                  handleDrawerToggle();
               }}
               to={"/programa-cumplimiento"}
               style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
            >
               <ListItem disablePadding>
                  <ListItemButton>
                     <ListItemText style={{ color: "#162c44" }} primary={"Programa de cumplimiento"} />
                  </ListItemButton>
               </ListItem>
            </NavLink>*/}
        <NavLink
          onClick={() => {
            setOpenServices(false);
            handleDrawerToggle();
          }}
          to={"/soluciones"}
          style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText
                style={{ color: "#162c44" }}
                primary={"Próximas soluciones"}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
      </Collapse>
      <Box
        sx={{
          px: 1,
          mx: 1,
          py: 3,
          "&:hover": {
            backgroundColor: "transparent",
            color: "white",
          },
        }}
      >
        <Typography sx={{ fontSize: "16px" }}>
          <a
            href="https://blog.lecosy.com.mx"
            rel="noreferrer"
            style={{
              fontSize: "19px",
              py: 1,
              textDecoration: "none",
              color: "#162c44",
              fontFamily: "Bold",
            }}
          >
            <strong>Blog</strong>
          </a>
        </Typography>
      </Box>
      <Box
        onClick={handleDrawerToggle}
        sx={{
          px: 1,
          mx: 1,
          py: 1,
          "&:hover": {
            backgroundColor: "#162c44",
            color: "white",
          },
        }}
      >
        <NavLink
          to={page("Contacto")}
          style={{ color: "#162c44", textDecoration: "none" }}
        >
          <Typography
            sx={{
              fontSize: "19px",
              py: 1,
              textTransform: "none",
              fontFamily: "Bold",
            }}
          >
            <strong>Contacto</strong>
          </Typography>
        </NavLink>
      </Box>
      <Box
        sx={{
          px: 1,
          mx: 1,
          py: 3,
          "&:hover": {
            backgroundColor: "transparent",
            color: "white",
          },
        }}
      >
        <Typography sx={{ fontSize: "16px" }}>
          <a
            href="https://web.lecosy.com.mx"
            rel="noreferrer"
            style={{
              fontSize: "19px",
              py: 1,
              textDecoration: "none",
              color: "#162c44",
              fontFamily: "Bold",
            }}
          >
            <strong>Ingresar</strong>
          </a>
        </Typography>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <NavBarThemeConfig>
      <AppBar
        component="nav"
        sx={{
          position: "absolute",
          backgroundColor: "transparent",
          boxShadow: 0,
          height: "15vh",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <IconButton
            color="default"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            onClick={() => navigate("/")}
            component={"img"}
            alt="Logo"
            src={
              "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png"
            }
            sx={{
              display: "flex",
              height: 70,
              ml: { sm: 2 },
              "&:hover": {
                cursor: "pointer",
              },
            }}
          />
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              marginLeft: "auto",
              mr: 5,
            }}
          >
            {pages.map((item) => (
              <Button
                key={item}
                onMouseEnter={() => sethoverComponent(item)}
                onMouseLeave={() => sethoverComponent("")}
                sx={{
                  mx: { md: 1, lg: 3 },
                  "&:hover": {
                    bgcolor: "transparent",
                    color: "#162c44",
                  },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <NavLink
                  to={page(item)}
                  style={({ isActive }) =>
                    isActive ? activeStyle : unactiveStyle
                  }
                >
                  <Typography
                    sx={{
                      textTransform: "none",
                      fontSize: "19px",
                      fontFamily: "Bold",
                      color: "primary",
                    }}
                  >
                    {item}
                  </Typography>
                  <Divider
                    color={"#162c44"}
                    sx={{
                      height: "3px",
                      display: hoverComponent === item ? "flex" : "none",
                    }}
                  />
                </NavLink>
              </Button>
            ))}
            <Box
              sx={{
                bgcolor: openServices ? "rgb(22,44,68,0.1)" : "transparent",
              }}
            >
              <ListItem onMouseEnter={handleClick}>
                <ListItemButton
                  sx={{
                    color: "#162c44",
                    "&:hover": { bgcolor: "transparent" },
                  }}
                >
                  <Typography fontFamily={"bold"} sx={{ fontSize: "19px" }}>
                    Soluciones
                  </Typography>
                  {!anchorEl ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </ListItemButton>
              </ListItem>
              <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}
              >
                <MenuItem
                  onClick={() => navigate("/canal-denuncias")}
                  sx={{ p: 2 }}
                >
                  <Typography style={{ color: "#162c44", fontSize: "14px" }}>
                    Canal de denuncias
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => navigate("/gobierno-corporativo")}
                  sx={{ p: 2 }}
                >
                  <Typography style={{ color: "#162c44", fontSize: "14px" }}>
                    Gobierno corporativo
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => navigate("/beneficiario-controlador")}
                  sx={{ p: 2 }}
                >
                  <Typography style={{ color: "#162c44", fontSize: "14px" }}>
                    Beneficiario controlador
                  </Typography>
                </MenuItem>
               
                {/*<MenuItem onClick={() => navigate("/programa-cumplimiento")} sx={{ p: 2 }}>
                           <Typography style={{ color: "#162c44", fontSize: "14px" }}>
                              Programa de cumplimiento
                           </Typography>
                        </MenuItem>*/}
                <MenuItem onClick={() => navigate("/soluciones")} sx={{ p: 2 }}>
                  <Typography style={{ color: "#162c44", fontSize: "14px" }}>
                    Próximas soluciones
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Button
              key={"blogbtn"}
              onMouseEnter={() => sethoverComponent("blogbtn")}
              onMouseLeave={() => sethoverComponent("")}
              sx={{
                mx: { md: 1, lg: 3 },
                "&:hover": {
                  bgcolor: "transparent",
                  color: "#162c44",
                },
                display: "flex",
                flexDirection: "column",
              }}
            >
              <a
                href="https://blog.lecosy.com.mx"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#162c44",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "none",
                    fontSize: "19px",
                    fontFamily: "Bold",
                    color: "primary",
                  }}
                >
                  Blog
                </Typography>
                <Divider
                  color={"#162c44"}
                  sx={{
                    height: "3px",
                    display: hoverComponent === "blogbtn" ? "flex" : "none",
                  }}
                />
              </a>
            </Button>
            <NavLink
              to={page("Contacto")}
              style={{ color: "#fff", textDecoration: "none" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#162c44",
                  borderRadius: "20px",
                  border: 3,
                  maxHeight: "80px",
                  py: "17px",
                  px: "22px",
                  mx: 2,
                  borderColor: "#162c44",
                  "&:hover": {
                    backgroundColor: "#5C6C7C",
                    borderColor: "#5C6C7C",
                  },
                }}
              >
                <Typography
                  sx={{
                    textTransform: "none",
                    fontSize: "16px",
                    color: "primary",
                  }}
                >
                  Contacto
                </Typography>
              </Box>
            </NavLink>
            <a
              href="https://web.lecosy.com.mx"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#162c44",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  bgcolor: "transparent",
                  maxHeight: "80px",
                  py: "17px",
                  px: "22px",
                  borderRadius: "20px",
                  border: 2,
                  borderColor: "#162c44",
                  "&:hover": {
                    backgroundColor: "#162c44",
                    color: "white",
                  },
                }}
              >
                <Typography fontFamily={"bold"}>Ingresar</Typography>
              </Box>
            </a>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </NavBarThemeConfig>
  );
};

NavBar.propTypes = {
  window: PropTypes.any,
};
