const principles = [
   {
      name: "Trabajo en equipo",
      description: "Capacidad de trabajar juntos hacia una visión común, demostrando compromiso y confianza.",
      img: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icono+VALOR_Trabajo+en+equipo.svg",
      key: "item1",
   },
   {
      name: "Integridad",
      description: "Capacidad de hacer lo correcto en todas las situaciones.",
      img: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icono+VALOR_Integridad.svg",
      key: "item2",
   },
   {
      name: "Justicia",
      description: "Busca el equilibrio entre el bien propio y el de la sociedad.",
      img: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icono+VALOR_Justicia.svg",
      key: "item3",
   },
   {
      name: "Responsabilidad",
      description: "Cumplir con los compromisos que adquirimos con nosotros mismos y con los demás.",
      img: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icono+VALOR_Responsabilidad.svg",
      key: "item4",
   },
   {
      name: "Empatía",
      description: "Capacidad de entender las necesidades de otros.",
      img: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icono+VALOR_Empatia.svg",
      key: "item5",
   },
   {
      name: "Interacción significativa",
      description:
         "Crear relaciones proactivas entre colaboradores, que genere flujo de ideas y un proceso de resolución de conflictos participativa.",
      img: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Iconos+VALORES_interaccion+significativa.svg",
      key: "item6",
   },
];

export default principles;
