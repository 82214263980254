import { Form, Formik } from "formik";
import { useState } from "react";
import {
   Box,
   Grid,
   Button,
   Typography,
   Dialog,
   DialogTitle,
   DialogContent,
   DialogContentText,
   DialogActions,
} from "@mui/material";
import { TextFieldThemeConfig } from "../config/TextFieldsTheme.config";
import { sendMailToConsultas } from "../lib/lecosyBackend";
import { emailSchema } from "../lib/validations/inputSchema.js";
import { InputTextField } from "./Inputs/InputTextField";
import { InputCheckBox } from "./Inputs/InputCheckBox";
import PropTypes from "prop-types";

export const WriteUs = ({ isDivided = false, isQuestion = false }) => {
   const [openDialog, setOpenDialog] = useState(false);

   const handleButtonPressed = async (values, actions) => {
      await sendMailToConsultas(
         values.name,
         values.lastName,
         values.charge,
         values.phoneNumber,
         values.email,
         values.message,
         values.company,
         values.ecAct
      );
      setOpenDialog(true);
      actions.resetForm();
   };

   return (
      <TextFieldThemeConfig>
         <Box>
            {isQuestion ? (
               <QuestionGrid onSubmit={handleButtonPressed} />
            ) : (
               <div>
                  {isDivided ? (
                     <WriteUsDividedGrid onSubmit={handleButtonPressed} />
                  ) : (
                     <WriteUsFullScreenGrid onSubmit={handleButtonPressed} />
                  )}
               </div>
            )}
         </Box>
         <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">{"¡Su mensaje se ha enviado correctamente!"}</DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-description" sx={{ textAlign: "center" }}>
                  Nos contactaremos contigo lo más pronto posible.
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={() => setOpenDialog(false)}>Cerrar</Button>
            </DialogActions>
         </Dialog>
      </TextFieldThemeConfig>
   );
};

const WriteUsDividedGrid = ({ onSubmit }) => {
   return (
      <Formik
         initialValues={{
            name: "",
            lastName: "",
            charge: "",
            phoneNumber: "",
            email: "",
            company: "",
            ecAct: "",
            message: "",

            checked: false,
         }}
         validationSchema={emailSchema(false)}
         onSubmit={onSubmit}
      >
         <Form>
            <Grid container spacing={2}>
               <Grid item xs={12} md={6}>
                  <InputTextField id={"name"} name={"name"} label={"Nombre"} />
               </Grid>
               <Grid item xs={12} md={6}>
                  <InputTextField id="lastName" name="lastName" label={"Apellido"} />
               </Grid>
               <Grid item xs={12} md={6}>
                  <InputTextField id="company" name={"company"} label={"Empresa"} />
               </Grid>
               <Grid item xs={12} md={6}>
                  <InputTextField id="charge" name="charge" label="Cargo" variant="outlined" />
               </Grid>
               <Grid item xs={12} md={6}>
                  <InputTextField id="ecAct" name="ecAct" label="Actividad económica" />
               </Grid>
               <Grid item xs={12} md={6}>
                  <InputTextField id="phoneNumber" name="phoneNumber" type="tel" label="Teléfono" />
               </Grid>
               <Grid item xs={12} md={12}>
                  <InputTextField id="email" name="email" type="email" label="Correo electrónico" />
               </Grid>
               <Grid item xs={12}>
                  <InputTextField id="message" name="message" label={"Mensaje"} multiline={true} rows={2} />
               </Grid>
               <Grid item xs={12} md={9} sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "auto",
                     }}
                  >
                     <InputCheckBox
                        id={"checked"}
                        name={"checked"}
                        label={
                           <Typography sx={{ fontSize: { xs: "15px", md: "auto" } }}>
                              He leído y acepto el{" "}
                              <a
                                 href="/Aviso-de-privacidad"
                                 target="_blank"
                                 style={{
                                    color: "#162c44",
                                    textDecoration: "none",
                                 }}
                              >
                                 <strong>Aviso de privacidad</strong>
                              </a>
                           </Typography>
                        }
                     />
                  </Box>
               </Grid>
               <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-end" } }}>
                  <Box sx={{ display: "flex", justifyContent: "center", py: { md: 2 } }}>
                     <Button
                        type="submit"
                        sx={{
                           px: 4,
                           borderRadius: 2.5,
                           "&:hover": { bgcolor: "#5c6c7c" },
                        }}
                     >
                        Enviar
                     </Button>
                  </Box>
               </Grid>
            </Grid>
         </Form>
      </Formik>
   );
};

const WriteUsFullScreenGrid = ({ onSubmit }) => {
   return (
      <Formik
         initialValues={{
            name: "",
            lastName: "",
            charge: "",
            phoneNumber: "",
            email: "",
            company: "",
            ecAct: "",
            message: "",

            checked: false,
         }}
         validationSchema={emailSchema(false)}
         onSubmit={onSubmit}
      >
         <Form>
            <Grid container spacing={2}>
               <Grid item xs={12} md={2}>
                  <InputTextField id={"name"} name={"name"} label={"Nombre"} />
               </Grid>
               <Grid item xs={12} md={2}>
                  <InputTextField id="lastName" name="lastName" label={"Apellido"} />
               </Grid>
               <Grid item xs={12} md={2}>
                  <InputTextField id="charge" name="charge" label="Cargo" variant="outlined" />
               </Grid>
               <Grid item xs={12} md={2}>
                  <InputTextField id="phoneNumber" name="phoneNumber" type="tel" label="Teléfono" />
               </Grid>
               <Grid item xs={12} md={4}>
                  <InputTextField id="email" name="email" type="email" label="Correo electrónico" />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Grid container spacing={1}>
                     <Grid item xs={12}>
                        <InputTextField id="company" name={"company"} label={"Empresa"} />
                     </Grid>
                     <Grid item xs={12}>
                        <InputTextField id="ecAct" name="ecAct" label="Actividad económica" />
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item xs={12} md={8} height={"100%"}>
                  <InputTextField id="message" name="message" label={"Mensaje"} multiline={true} rows={3} />
               </Grid>
               <Grid item xs={12} md={9} sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "center",
                     }}
                  >
                     <InputCheckBox
                        id={"checked"}
                        name={"checked"}
                        label={
                           <Typography sx={{ fontSize: { xs: "15px", md: "auto" } }}>
                              He leído y acepto el{" "}
                              <a
                                 href="/Aviso-de-privacidad"
                                 target="_blank"
                                 style={{
                                    color: "#162c44",
                                    textDecoration: "none",
                                 }}
                              >
                                 <strong>Aviso de privacidad</strong>
                              </a>
                           </Typography>
                        }
                     />
                  </Box>
               </Grid>
               <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-end" } }}>
                  <Box sx={{ display: "flex", justifyContent: "center", py: { md: 2 } }}>
                     <Button
                        type="submit"
                        sx={{
                           px: 6,
                           borderRadius: 2.5,
                           "&:hover": { bgcolor: "#5c6c7c" },
                        }}
                     >
                        Solicitar contacto
                     </Button>
                  </Box>
               </Grid>
            </Grid>
         </Form>
      </Formik>
   );
};

const QuestionGrid = ({ onSubmit }) => {
   return (
      <Formik
         initialValues={{
            email: "",
            message: "",

            checked: false,
         }}
         validationSchema={emailSchema(true)}
         onSubmit={onSubmit}
      >
         <Form>
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <InputTextField id="email" name="email" type="email" label="Correo electrónico" />
               </Grid>
               <Grid item xs={12}>
                  <InputTextField id="message" name="message" label={"Pregunta"} multiline={true} rows={3} />
               </Grid>
               <Grid item xs={12} md={9} sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "center",
                     }}
                  >
                     <InputCheckBox
                        id={"checked"}
                        name={"checked"}
                        label={
                           <Typography sx={{ fontSize: { xs: "15px", md: "auto" } }}>
                              He leído y acepto el{" "}
                              <a
                                 href="/Aviso-de-privacidad"
                                 target="_blank"
                                 style={{
                                    color: "#162c44",
                                    textDecoration: "none",
                                 }}
                              >
                                 <strong>Aviso de privacidad</strong>
                              </a>
                           </Typography>
                        }
                     />
                  </Box>
               </Grid>
               <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-end" } }}>
                  <Box sx={{ display: "flex", justifyContent: "center", py: { md: 2 } }}>
                     <Button
                        type="submit"
                        sx={{
                           px: 6,
                           borderRadius: 2.5,
                           "&:hover": { bgcolor: "#5c6c7c" },
                        }}
                     >
                        Enviar
                     </Button>
                  </Box>
               </Grid>
            </Grid>
         </Form>
      </Formik>
   );
};

WriteUs.propTypes = {
   isDivided: PropTypes.bool,
   isQuestion: PropTypes.bool,
};

WriteUsDividedGrid.propTypes = {
   onSubmit: PropTypes.func,
};

WriteUsFullScreenGrid.propTypes = {
   onSubmit: PropTypes.func,
};

QuestionGrid.propTypes = {
   onSubmit: PropTypes.func,
};
