import { ThemeConfig } from "../config/theme.config";
import { Box, Typography } from "@mui/material";
import ScrollToTop from "../wrapper/scrollToTop";
import { Footer } from "../components";

export const PrivacyScreen = () => {
   return (
      <ThemeConfig>
         <ScrollToTop>
            <Box sx={{ px: { xs: 1, md: 15, lg: 30 } }}>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: { xs: "column", md: "row" },
                     alignItems: { xs: "center", md: "auto" },

                     py: 5,
                     mb: 2,
                  }}
               >
                  <Box sx={{ display: "flex", flex: 1 }}>
                     <Box
                        component={"img"}
                        src={"https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png"}
                        sx={{ display: "flex", height: 70 }}
                     />
                  </Box>
                  <Typography
                     color={"primary"}
                     variant="h4"
                     sx={{ display: "flex", flex: 2, alignItems: "center", fontFamily: "Bold", textAlign: "center" }}
                  >
                     Políticas de privacidad
                  </Typography>
               </Box>
               <Box sx={{ px: { xs: 3, md: 0 }, py: 8 }}>
                  <Typography align="justify" lineHeight={1.75}>
                     <strong>LECOSY, S.A.P.I. DE C.V.,</strong> con domicilio en Calle General José María Yañez número
                     8, Colonia San Benito, C.P. 83190, Hermosillo, Sonora, es responsable de los datos personales que
                     ha recabado o que recabe de sus clientes o de cualquier otra persona física o moral, del uso que se
                     dé a los mismos y de su debida protección. En este contexto y conforme a lo dispuesto por los
                     artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de los
                     Particulares (así como de los artículos relativos y aplicables de su Reglamento) se hace de su
                     conocimiento, mediante el presente documento, las características y finalidades del tratamiento que
                     se dará a sus datos personales.
                     <br />
                     <br />
                     Los datos personales que <strong>LECOSY, S.A.P.I. DE C.V.,</strong> recabe, almacena y use de sus
                     clientes o prospectos, le son proporcionados de manera voluntaria por cada titular, para efectos de
                     la relación de "prestación de servicios y/o relación comercial". Consecuentemente, dichos datos
                     personales son recopilados única y exclusivamente para: I.- Recopilación de información necesaria
                     del cliente o prospecto para dar inicio a la prestación de nuestros servicios, conocer sus
                     necesidades de productos o servicios y estar en posibilidad de ofrecerle los que más se adecuen a
                     sus preferencias, así como al seguimiento a cualquier relación contractual entre el titular de los
                     datos personales y nuestra empresa, II.- Fines fiscales y/o de facturación, III.- Comercializar
                     software y equipos electrónicos y IV.- Cualquier finalidad análoga o compatible con las anteriores.
                     De igual manera, cualquier persona física puede enviar voluntariamente sus datos (incluyendo
                     curriculum vitae) a <strong>LECOSY, S.A.P.I. DE C.V.,</strong> a efecto de que podamos contactarlo
                     respecto de una posible contratación o para enviarle aquella información que de nuestra empresa
                     resulte de su interés.
                     <br />
                     <br />
                     Para las finalidades antes mencionadas, los datos personales recabados y/o a recabarse serán, según
                     corresponda:
                     <br />
                     <br />
                     <strong>DATOS DE IDENTIFICACIÓN DE PERSONAS FÍSICAS:</strong> Nombre, nacionalidad, fecha y lugar
                     de nacimiento, edad, sexo, estado civil, domicilio, ocupación, nombres de familiares, dependientes
                     y beneficiarios, identificación oficial vigente, Registro Federal de Contribuyentes (RFC), Clave
                     Única de Registro de Población (CURP), teléfono particular, teléfono celular y correo electrónico.
                     <br />
                     <br />
                     <strong>DATOS ACADÉMICOS DE PERSONAS FÍSICAS:</strong> Trayectoria educativa, títulos, cédula
                     profesional, y reconocimientos, entre otros.
                     <br />
                     <br />
                     <strong>DATOS LABORALES DE PERSONAS FÍSICAS:</strong> Puesto desempeñado, domicilio de trabajo,
                     teléfono, correo electrónico de trabajo, referencias laborales y personales con Nombre, domicilio,
                     teléfono y relación.
                     <br />
                     <br />
                     <strong>DATOS GENERALES DE PERSONAS MORALES:</strong> Razón social, nombre del apoderado y/o
                     representante legal, domicilio social, código postal, correo electrónico institucional y teléfono
                     fijo.
                     <br />
                     <br />
                     <strong>DOCUMENTOS PÚBLICOS EN GENERAL:</strong> Identificaciones oficiales, escrituras públicas de
                     actas constitutivas o asambleas, poderes, entre otras, inscritas ante el Registro Público de la
                     Propiedad y Comercio, oficios de entidades gubernamentales, etcétera.
                     <br />
                     <br />
                     <strong>DOCUMENTOS PRIVADOS EN GENERAL:</strong> Documentos no inscritos ante el Registro Público
                     de la Propiedad y Comercio, diferentes tipos de actas, contratos, poderes, descripciones escritas o
                     verbales de proyectos para su negocio, empresa o marca, entre otros.
                     <br />
                     <br />
                     <strong>DATOS PATRIMONIALES:</strong> Cuentas bancarias y/o formas de pago.
                     <br />
                     <br />
                     Mediante el presente instrumento jurídico, se hace de su conocimiento que{" "}
                     <strong>LECOSY, S.A.P.I. DE C.V.,</strong> no recaba datos personales sensibles; Entendiéndose como
                     datos personales sensibles lo señalado en el artículo 3, Fracción VI de la Ley que nos ocupa, mismo
                     que es del tenor literal siguiente:
                     <br />
                     <br />
                     <Box sx={{ px: { xs: 2, md: 5, lg: 10 } }}>
                        <Typography lineHeight={1.75}>
                           “Aquellos datos personales que afecten a la esfera más íntima de su titular, o cuya
                           utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste.
                           En particular, se consideran sensibles aquellos que puedan revelar aspectos como origen
                           racial o étnico, estado de salud presente y futuro, información genética, creencias
                           religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia
                           sexual.”
                        </Typography>
                     </Box>
                     <br />
                     <br />
                     En caso de que consideremos necesario que nos proporcione información y/o datos personales
                     considerados sensibles para efectos de brindarle de manera integral alguno de nuestros servicios,
                     le haremos de su conocimiento dicha situación, por lo cual, para recabar y tratar datos personales
                     del titular usted nos deberá otorgar su consentimiento expreso y por escrito de su parte, a través
                     de nuestro formato de consentimiento de tratamiento de datos personales autorizado por{" "}
                     <strong>LECOSY, S.A.P.I. DE C.V.</strong>
                     <br />
                     <br />
                     <strong>LECOSY, S.A.P.I. DE C.V.,</strong> y todo su personal reconocemos la importancia de un
                     adecuado tratamiento para sus datos personales y, como consecuencia de ello, se han implementado
                     diversos controles y medidas de seguridad tales como Políticas de Privacidad, Guía para el borrado
                     seguro de datos personales, implementación del sistema de gestión de seguridad de datos personales,
                     que permiten que dichos datos personales conserven su carácter confidencial y previenen tanto un
                     acceso o uso no autorizado como posibles daños, pérdidas o alteraciones a los mismos.
                     <br />
                     <br />
                     Los datos personales recabados por <strong>LECOSY, S.A.P.I. DE C.V.,</strong> no se comparten ni se
                     transfieren con terceros a excepción de aquellos que fueran requeridos por autoridades federales o
                     locales, aquellos respecto de los cuales usted hubiera dado su consentimiento en forma previa o
                     aquellos que usted nos haya proporcionado para que sean presentados o transferidos a terceros
                     determinados como parte de un trámite o un asunto legal. Dichos datos personales son y/o serán
                     utilizados solamente por el personal autorizado al efecto y en relación con las finalidades antes
                     mencionadas (atendiendo en todo momento a los principios de protección de datos establecidos por
                     Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento).
                     <br />
                     <br />
                     No obstante, si usted tiene cualquier duda o inquietud acerca de nuestras prácticas de privacidad
                     le pedimos se ponga en contacto con nosotros a través de la dirección electrónica&nbsp;
                     <a href="mailto: administrador@lecosy.com.mx">administrador@lecosy.com.mx</a> o bien comunicarse al
                     departamento de tratamiento de datos personales con número telefónico{" "}
                     <strong>(662) 285 48 72,</strong> quien es el responsable de guardar y custodiar la información de
                     datos personales. De igual forma, si usted no desea recibir cualquier aviso le solicitamos lo haga
                     de nuestro conocimiento.
                     <br />
                     <br />
                     Usted podrá revocar el consentimiento que nos haya otorgado y que fuere necesario para el
                     tratamiento de sus datos personales a fin de que los mismos se dejen de usar. Para dichos efectos,
                     deberá enviar su petición a la dirección electrónica señalada en el párrafo anterior o bien
                     presentarla por escrito en el domicilio de la empresa o por llamada al número{" "}
                     <strong>(662) 285 48 72,</strong> en atención al departamento de tratamiento de datos personales,
                     quien es el responsable de guardar y custodiar la información de datos personales y en un plazo
                     máximo de 20 días hábiles a partir de la recepción de la misma dará respuesta a esta solicitud.
                     <br />
                     <br />
                     De igual forma, usted tiene derecho de acceder a los datos personales que{" "}
                     <strong>LECOSY, S.A.P.I. DE C.V.,</strong> posea en relación a su persona y a los detalles del
                     tratamiento de los mismos, así como también tiene derecho a actualizarlos o rectificarlos de ser
                     éstos inexactos o incorrectos, a instruirnos su cancelación (cuando se hayan cumplido las
                     finalidades para las cuales fueron recabados) y a oponerse al tratamiento de los mismos para fines
                     específicos o por existir causa legítima para ello. El ejercicio de los derechos de acceso,
                     rectificación, cancelación y oposición antes mencionados podrá hacerse valer mediante escrito que
                     presente en las oficinas de <strong>LECOSY, S.A.P.I. DE C.V.,</strong> siempre y cuando cumpla con
                     la información y anexos mínimos descritos por la Ley Federal de Protección de Datos Personales en
                     Posesión de los Particulares y su Reglamento (1. El nombre del titular y domicilio u otro medio
                     para comunicarle la respuesta a su solicitud, 2. Copia de un documento que acredite su identidad,
                     3. La descripción clara y precisa de los datos personales sobre los que busca ejercer alguno de
                     estos derechos y 4. Descripción clara y precisa de su solicitud), teniendo la empresa el compromiso
                     de dar contestación dentro de los plazos establecidos por dichos ordenamientos.
                     <br />
                     <br />
                     Habiéndose puesto a su disposición el presente aviso de privacidad y usted no manifieste su derecho
                     de oposición al respecto, se considerará por Ley que usted le otorga a{" "}
                     <strong>LECOSY, S.A.P.I. DE C.V.,</strong>
                     consentimiento tácito, para que podamos llevar a cabo recopilación, tratamiento y generación de
                     datos personales del titular bajo los lineamientos establecidos en el presente instrumento jurídico
                     y según lo establecido por los artículos 3 fracción IV, V, 7, 8, 12, 17 y 37 fracción V de la Ley;
                     asimismo, cabe hacer mención que sin la necesidad de su consentimiento podremos allegarnos y/o
                     hacer tratamiento de sus datos personales que figuren en fuentes del dominio público según lo
                     dispuesto en el artículo 10 fracción II de la Ley; por otra parte, de las obligaciones derivadas de
                     nuestra relación jurídica podremos recabar, tratar (o en su caso generar) sus datos personales
                     financieros y/o patrimoniales, sin necesidad de que usted nos otorgue consentimiento expreso según
                     lo dispuesto en el artículo 8 cuarto párrafo en relación con el artículo 10 fracción IV de la Ley
                     por estar ligado a los servicios contratados con <strong>LECOSY, S.A.P.I. DE C.V;</strong> Al no
                     oponerse al presente aviso de privacidad usted declara y acepta que cuenta con el consentimiento
                     propio y de aquellos terceros de los cuales usted proporcione sus datos en el entendido de que
                     dichos datos se regirán por los lineamientos legales del presente instrumento jurídico y la
                     política de privacidad de la empresa.
                     <br />
                     <br />
                     <strong>LECOSY, S.A.P.I. DE C.V.,</strong> se reserva el derecho de actualizar y/o modificar los
                     términos del presente aviso de privacidad en cualquier momento, en cuyo caso, comunicará dicha
                     situación mediante un correo electrónico a la dirección que usted nos haya proporcionado.
                     <br />
                     <br />
                     Finalmente, le solicitamos a fin de cumplir con el principio de calidad en el tratamiento de datos
                     personales que, de ser necesaria una actualización o una corrección en sus datos personales, se
                     ponga en contacto con nosotros para así permitirnos contar con una base de datos exacta, completa,
                     correcta y actualizada.
                  </Typography>
                  <Typography sx={{ textAlign: "right" }}>
                     <br />
                     <br />
                     Hermosillo, Sonora a los 29 días del mes de mayo de 2023.
                  </Typography>
               </Box>
            </Box>
            <Footer />
         </ScrollToTop>
      </ThemeConfig>
   );
};
