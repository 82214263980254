import axios from "axios";

const host = "https://server.lecosy.com.mx/cd";
const host2 = "https://server.lecosy.com.mx";


export const sendMailToConsultas = async (
  name,
  lastName,
  position,
  phone,
  email,
  body,
  company,
  ecAct
) => {
  const payload = {
    name,
    lastName,
    position,
    phone,
    email,
    body,
    company,
    ecAct,
  };
  return await axios.post(`${host}/api/client/mail/send/consultas`, payload);
};

/**
 * Function to handle sending an email with the form data and name.
 *
 * @param {Object} params - The function parameters.
 * @param {string} params.email - The recipient's email address.
 * @param {Object} params.formData - The form data to be sent in the email.
 * @param {string} params.name - The name to be included in the email.
 * @returns {Promise<Object>} The response data from the API.
 * @throws {Error} If the API request fails.
 */
export const sendMailRequest = async ({ email, formData, name }) => {
  const payload = {
    email,
    formData,
    name,
  };

  try {
    const response = await axios.post(
      `${host2}/api/client/mail/send/send-governance-pdf`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error in sendMailRequest:", error);
    throw error;
  }
};


// This won'be used right now
/**
 * Function to handle sending a WhatsApp message with the form data and name.
 *
 * @param {Object} params - The function parameters.
 * @param {string} params.phoneNumber - The recipient's phone number.
 * @param {Object} params.formData - The form data to be sent in the WhatsApp message.
 * @param {string} params.name - The name to be included in the WhatsApp message.
 * @returns {Promise<Object>} The response data from the API.
 * @throws {Error} If the API request fails.
 */
export const sendWhatsAppRequest = async ({ phoneNumber, formData, name }) => {
  const payload = {
    phoneNumber,
    formData,
    name,
  };
  try {
    const response = await axios.post(
      `${host2}/api/client/send-governance-whatsapp`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error in sendWhatsAppRequest:", error);
    throw error;
  }
};
