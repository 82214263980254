const compromisesContent = [
   "Garantizar el entendimiento y cumplimiento de la normatividad aplicable, tanto para nuestros clientes como para nuestra organización, estableciendo una cultura de cumplimiento.",
   "Actuar bajo nuestro código de ética, principios, y valores, siendo un ejemplo de un mundo mejor.",
   "Fomentar la participación activa de nuestros profesionales y partes interesadas para generar información valiosa sobre temas que beneficien al mundo corporativo.",
   "Mantener la integridad y transparencia de los procesos; previniendo las actividades ilícitas, la corrupción y el soborno.",
   "Promover la mejora continua en la organización a fin de optimizar y contribuir al desempeño del Sistema Integrado de Gestión, garantizando la integración de sus elementos con otros sistemas.",
   "Impulsar el desarrollo de nuestros clientes y líderes a través de la tecnología, fomentando su profesionalización, adopción de buenas prácticas en sus actividades y propiciando la continuidad de la organización.",
   "Actuar responsablemente en nuestras operaciones diarias, asegurando el bienestar del medio ambiente y de la comunidad.",
   "Superar las expectativas de nuestros clientes al ofrecer soluciones disruptivas, multidisciplinarias, eficientes, confiables y en un solo lugar que resuelvan sus necesidades de manera efectiva.",
];

export default compromisesContent;
