import { Box, Grid, Stack, Typography, Button } from "@mui/material";

const ExternBlogSection = (names, logos, messages, links) => {
   const blogContainer = (i) => {
      return (
         <Stack direction={"column"}>
            <Box sx={{ width: "100%", display: "flex", alignContent: "center", justifyContent: "center" }}>
               <Box
                  sx={{
                     width: { xs: "120px", md: "130px" },
                     backgroundImage: `url(${logos[i]})`,
                     backgroundSize: "contain",
                     backgroundPosition: "center center",
                     backgroundRepeat: "no-repeat",
                     aspectRatio: "1/1",
                  }}
               />
            </Box>
            {messages[i]}
            <Typography textAlign={"center"} sx={{ mt: { xs: 3 } }}>
               <strong>Para mayor información:</strong>
            </Typography>
            <Box width={"100%"} display={"flex"} sx={{ justifyContent: "center" }}>
               <Button
                  href={links[i]}
                  target="_blank"
                  sx={{
                     bgcolor: "162c44",
                     color: "white",
                     mt: 2,
                     px: 2,
                     "&:hover": { bgcolor: "#5c6c7c" },
                  }}
               >
                  <Typography sx={{ textTransform: "none", fontFamily: "Bold", color: "primary" }}>
                     Visitar blog {names[i]}
                  </Typography>
               </Button>
            </Box>
         </Stack>
      );
   };
   return (
      <Stack direction={"row"} bgcolor={"#F7F7F7"}>
         <Grid container>
            {names.map((name, i) => {
               return (
                  <Grid
                     item
                     xs={12}
                     md={6}
                     key={name}
                     justifyContent={"center"}
                     sx={{ px: { xs: 3, md: 10, lg: 20 }, py: { xs: 2, md: 10 } }}
                  >
                     {blogContainer(i)}
                  </Grid>
               );
            })}
         </Grid>
      </Stack>
   );
};

export default ExternBlogSection;
