import { Checkbox, FormControl, FormGroup, FormControlLabel, FormHelperText } from "@mui/material";
import { useField } from "formik";

export const InputCheckBox = ({ id, name, label }) => {
   const [field, meta] = useField(name);
   return (
      <FormControl required error={meta.error && meta.touched ? true : false}>
         <FormGroup>
            <FormControlLabel control={<Checkbox {...field} />} label={label} />
         </FormGroup>
         <FormHelperText>{meta.error && meta.touched ? meta.error : null}</FormHelperText>
      </FormControl>
   );
};
