import XMLParser from "react-xml-parser";

export const getWordpressBlog = async () => {
   const response = await fetch("https://blog.lecosy.com.mx/index.php/popular-posts");
   const data = await response.text();
   const xml = new XMLParser().parseFromString(data);
   
   const itemsFound = xml.children[0].children
      .filter(i => i.children.some(c => c.name === "category" &&
         !c.value.includes("Formularios y herramientas") &&
         !c.value.includes("Consejos prácticos")))
      .slice(0, 3);
   
   return itemsFound;
};


export const getImageUrl = (description) => {
   const imageSrc = description.indexOf("src=");
   let imageExt = description.indexOf("jpg");
   if (imageExt === -1) imageExt = description.indexOf("png");
   return description.substring(imageSrc + 5, imageExt + 3);
};

export const getCroppedDescription = (description) => {
   const descSrc = description.indexOf("/>");
   return `${description.substring(descSrc + 1, descSrc + 70)}...`;
};

export const replaceCharacterWithSpace = (text) => {
   let newText = text;
   if (newText.includes("%20")) {
      newText = replaceCharacterWithSpace(newText.replace("%20", " "));
   }
   return newText;
};
