import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

export const ModuleCategoryCard = ({ description, mainImg, darkbg = false, useIcons = false, subDescription }) => {
   const [iconScale, setIconScale] = React.useState(0);
   const maxHeightValue = 3596;
   window.addEventListener("scroll", () => {
      let scroll = window.scrollY;
      const percentage = (scroll * 100) / maxHeightValue;
      const normalized = ((percentage - 40) / (91 - 40)) * 1.5;
      if (normalized < 0.7 || normalized > 1.3) return;
      setIconScale(normalized);
   });

   return (
      <Card
         sx={{
            display: "flex",
            justifyContent: "center",
            border: "none",
            boxShadow: "none",
            backgroundColor: "transparent",
            position: "flex",
         }}
      >
         <CardContent>
            {!useIcons ? (
               <Box
                  component={"img"}
                  src={mainImg}
                  alt={description}
                  sx={{
                     display: "flex",
                     width: "100%",
                     height: "4rem",
                     justifyContent: "center",
                     mb: 1,
                     transform: { lg: `scale(${iconScale})` },
                  }}
               />
            ) : (
               <Box sx={{ display: "flex", justifyContent: "center", color: "white" }}>{mainImg}</Box>
            )}
            <Typography
               gutterBottom
               component="div"
               textAlign="center"
               fontFamily={"bold"}
               sx={{ color: darkbg ? "white" : "black" }}
            >
               {description}
            </Typography>
            <Typography textAlign="center" sx={{ px: { md: 10 }, color: darkbg ? "white" : "black" }}>
               {subDescription}
            </Typography>
         </CardContent>
      </Card>
   );
};

ModuleCategoryCard.propTypes = {
   description: PropTypes.string.isRequired,
   mainImg: PropTypes.string.isRequired,
   darkbg: PropTypes.bool,
   useIcons: PropTypes.bool.isRequired,
   subDescription: PropTypes.string.isRequired,
};
