import { Box, Divider, Stack } from "@mui/material";
import { VisibleElement } from "../lib/hooks/VisibleElement";
import React, { useRef, useState } from "react";
import ReactPlayer from "react-player/lib";
import PropTypes from "prop-types";
import { AnimationOnScroll } from "react-animation-on-scroll";

const VideoContainer = ({
   videoUrl,
   direction = "row",
   title,
   subTitle,
   subComponent,
   alternComponent,
   videoPlayerProps,
}) => {
   const videoIsVisible = useRef(null);
   const [isPlaying, setIsPlaying] = useState(false);
   const portview = videoIsVisible !== null ? VisibleElement(videoIsVisible, `-180px`) : false;

   return (
      <Stack direction={"column"} spacing={2} py={5}>
         {title ? (
            <div>
               <Box
                  color={"#46566A"}
                  display={"flex"}
                  minWidth={{ lg: "45%" }}
                  maxWidth={{ lg: "60%" }}
                  justifyContent={"flex-start"}
               >
                  {title}
               </Box>
               <AnimationOnScroll animateIn="animate__slideInLeft" duration={0.75} animatePreScroll={false} animateOnce>
                  <Divider
                     sx={{ borderBottomWidth: "4px", maxWidth: "45%", bgcolor: "#46566A" }}
                     minWidth={{ lg: "45%" }}
                  />
               </AnimationOnScroll>
            </div>
         ) : undefined}
         <Stack direction={{ xs: direction === "row" ? "column" : "column-reverse", md: direction }} spacing={5} py={5}>
            <Stack direction={"column"} alignItems={"center"} spacing={1} flex={1}>
               {subTitle}
               <Box
                  sx={{
                     width: "100%",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     flexDirection: "column",
                  }}
               >
                  <Box
                     ref={videoIsVisible}
                     {...videoPlayerProps}
                     sx={{
                        width: "80%",
                        maxWidth: "800px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                     }}
                  >
                     <ReactPlayer
                        playing={isPlaying && portview}
                        muted
                        onReady={() => setIsPlaying(true)}
                        url={videoUrl}
                        controls
                        width="100%"
                        height="auto"
                     />
                     <Box overflow={"hidden"} width="100%">
                        <AnimationOnScroll
                           animateIn="animate__slideInLeft"
                           animateOnce
                           animatePreScroll={false}
                           duration={0.75}
                           initiallyVisible
                        >
                           <Divider sx={{ borderBottomWidth: 5, bgcolor: "#8A95A1", mt: 1 }} />
                        </AnimationOnScroll>
                     </Box>
                  </Box>
               </Box>
               <Box py={4} width={"100%"} minHeight={"150px"}>
                  {subComponent}
               </Box>
            </Stack>
            <Box flex={1}>{alternComponent}</Box>
         </Stack>
      </Stack>
   );
};

VideoContainer.propTypes = {
   videoUrl: PropTypes.string.isRequired,
   direction: PropTypes.oneOf(["row", "column"]),
   title: PropTypes.node,
   subTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
   subComponent: PropTypes.node,
   alternComponent: PropTypes.node,
   videoPlayerProps: PropTypes.object,
};

export default VideoContainer;