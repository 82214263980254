import { Stack, Typography } from "@mui/material";

export const homeQuestions = [
   {
      title: "question1",
      question: "¿Para quién es este sistema?",
      answer:
         "El sistema de Lecosy ha sido diseñado para ser aplicable a cualquier organización, sin importar su tamaño o sector. Su objetivo principal es asistir a la organización en su cumplimiento con las leyes y regulaciones pertinentes, así como con las políticas internas y estándares éticos establecidos.",
      list: [],
   },
   {
      title: "question2",
      question: "¿Por qué mi organización necesita establecer un sistema de programa de cumplimiento?",
      answer:
         "Tener un programa de cumplimiento es esencial para cualquier organización, ya que ayuda a prevenir situaciones de riesgo, evita multas y sanciones, protege la reputación de la empresa, mejora la gestión empresarial y fomenta la cultura de la ética y la legalidad. En definitiva, un programa de cumplimiento bien diseñado a las necesidades de la organización puede ser clave para el éxito y sostenibilidad de la misma, especialmente en sectores altamente regulados donde el incumplimiento de normativas puede tener graves consecuencias.",
      list: [],
   },
   {
      title: "question3",
      question: "¿De qué módulos se compone el sistema de Lecosy?",
      answer: "Los módulos que comprende el sistema son:",
      list: [
         "Canal de denuncias",
         "Programa de cumplimiento",
         "Gestión de riesgos",
         "Correlación de leyes",
         "Auditoría interna",
         "Buenas prácticas empresariales",
         "Gobierno corporativo",
         "Responsabilidad Corporativa",
      ],
   },
   {
      title: "question4",
      question: "¿Qué módulos están disponibles actualmente?",
      answer:
         "Actualmente, el sistema Lecosy ofrece el módulo de canal de denuncias con la opción sin gestión y con gestión incluida.",
      list: [],
   },
   {
      title: "question5",
      question: "¿Cuál plan debo elegir?",
      answer: "",
      list: [
         <Typography key={1}>
            <strong>Contratación de Lecosy con gestión incluida.</strong> Ideal para organizaciones de cualquier tipo y
            tamaño que necesitan un equipo especializado que se encargue de gestionar sus denuncias, quejas y/o
            sugerencias de manera profesional y eficiente.
         </Typography>,
         <Typography key={2}>
            <strong>Contratación de Lecosy sin gestión.</strong> Dirigida a organizaciones que cuenten con la estructura
            necesaria para autogestionar sus denuncias, quejas y/o sugerencias.
         </Typography>,
      ],
   },
   {
      title: "question6",
      question: "¿Qué ventajas obtendré con esta herramienta?",
      answer: "",
      list: [
         "Reducción de riesgos y protección del patrimonio.",
         "Protege la reputación de su organización: previene situaciones negativas que puedan dañar la imagen de esta.",
         "Mejora de la eficacia operativa: obliga a establecer procesos y controles más claros y eficientes.",
         "Fomento de la cultura de la ética y la legalidad: aumenta la motivación y compromiso de los empleados y mejora la percepción del público.",
         "Demuestra compromiso con el cumplimiento normativo y la ética empresarial.",
      ],
   },
   {
      title: "question7",
      question: "¿Cuáles son sus horas de atención al cliente?",
      answer: "El horario de atención a clientes y soporte técnico es de 08:00 a 18:30 de lunes a viernes.",
      list: [],
   },
];

export const DenounceQuestions = [
   {
      title: "question1",
      question: "¿Para quién es este sistema?",
      answer:
         "Organizaciones de cualquier tamaño y sector económico, que deseen implementar acciones oportunas para mantener un debido control sobre la operatividad de sus negocios.",
      list: [],
   },
   {
      title: "question2",
      question: "¿Por qué necesito un sistema de gestión de denuncias?",
      answer:
         "Contar con un canal de denuncias en su organización puede ser muy beneficioso para usted. Con un canal de denuncias puede identificar y abordar rápidamente cualquier conducta inapropiada, lo que reduce el riesgo de responsabilidad legal y protege la reputación de su organización. Además, tener un canal de denuncias puede aumentar la transparencia y la confianza de los empleados en la cultura y los valores de su organización. Esto significa que sus empleados podrán hablar abiertamente y sin miedo de cualquier problema o preocupación que tengan, lo que puede mejorar la moral y la eficacia del equipo.",
      list: [],
   },
   {
      title: "question3",
      question: "¿Es necesario instalar algún software para utilizar el sistema?",
      answer:
         "No es necesario instalar ningún software para utilizar el canal de denuncias, ya que se trata de un sistema en la nube. Esto significa que se puede acceder al sistema desde cualquier equipo de cómputo con conexión a internet, sin necesidad de descargar o instalar ningún programa adicional en el equipo. Por lo tanto, el uso del canal de denuncias es muy sencillo y accesible para todos los usuarios.",
      list: [],
   },
   {
      title: "question4",
      question: "¿Cuál es el límite de denuncias?",
      answer:
         "Nuestro sistema de canal de denuncias no tiene límites en cuanto al número de denuncias, quejas y/o sugerencias que pueden presentar los stakeholders de su organización. Tu organización no debe preocuparse por alcanzar un límite o tener que pagar tarifas adicionales.",
      list: [],
   },
   {
      title: "question5",
      question: "¿Puedo cambiar la modalidad contratada de Gestión?",
      answer:
         "Su organización puede cambiar la modalidad contratada de gestión en cualquier momento. Si actualmente tiene contratado el servicio de Gestión interna, y desea cambiar a la modalidad de Gestión Lecosy, nuestro equipo especializado se encargará de todos los procesos de la gestión de todas las denuncias, lo que le permitirá tener una mayor tranquilidad y una gestión más eficiente. Por otro lado, si actualmente tiene contratado el servicio de Gestión Lecosy, y desea cambiar a la modalidad de gestión interna, podrá gestionar todas las denuncias internamente en su organización. En ambos casos, nuestro equipo de soporte estará disponible para brindarle la asistencia necesaria durante el proceso de cambio.",
      list: [],
   },
   {
      title: "question6",
      question: "¿Cuál es el plazo de contratación?",
      answer:
         "Los plazos de contratación del canal de denuncias pueden ser de 6 o 12 meses, según lo que mejor se adapte a las necesidades de tu organización.",
      list: [],
   },
];
export const BeneficialOwnerQuestions = [
   {
      title: "question1",
      question: "¿La obligación del beneficiario controlador a quien aplica?",
      answer: (
         <Stack direction={"column"} spacing={1}>
            <Typography>
            Según el artículo 32-B Ter del Código Fiscal de la Federación, aplica a los siguientes sujetos: Personas Morales, Fideicomitentes, Fideicomisarios, Fiduciarias, y cualquier otra figura jurídica (Coinversión, Copropiedad empresarial, Asociación en Participación, etc.)
            </Typography>
         </Stack>
      ),
      list: [],
   },
   {
      title: "question2",
      question: "¿Por qué necesito este módulo para prevenir sanciones onerosas?",
      answer: (
         <Stack direction={"column"} spacing={1}>
            <Typography>
               La autoridad fiscal, según lo descrito anteriormente, y en la regla 2.8.1.21. de la Resolución Miscelánea Fiscal establece que debe existir mecanismos para identificar, obtener y conservar la información actualizada sobre el beneficiario controlador.
            </Typography>
            <Typography>
               Es decir, implementar procedimientos de control internos debidamente documentados que aseguren la gestión integral del beneficiario en función del cumplimiento de dicha obligación sujeta.
            </Typography>
         </Stack>
      ),
      list: [],
   },
   {
      title: "question3",
      question: "¿Es necesario instalar algún software para utilizar el sistema?",
      answer: (
         <Stack direction={"column"} spacing={1}>
            <Typography>
               No es necesario instalar ningún software para utilizar el módulo, ya que se trata de un sistema en la nube.
            </Typography>
            <Typography>
               Esto significa que se puede acceder al sistema desde cualquier equipo de cómputo con conexión a internet, sin necesidad de descargar o instalar ningún programa adicional en el equipo.
            </Typography>
         </Stack>
      ),
      list: [],
   },
   {
      title: "question4",
      question: "¿Cuál es el límite de beneficiarios controladores que puedo gestionar?",
      answer: (
         <Stack direction={"column"} spacing={1}>
            <Typography>
               Nuestro módulo de beneficiario controlador no tiene límites en cuanto al número de sujetos que se identifiquen.
            </Typography>
            <Typography>
               Su organización no debe preocuparse por alcanzar un límite o tener que pagar tarifas adicionales.
            </Typography>
         </Stack>
      ),
      list: [],
   },
   {
      title: "question5",
      question: "¿Cuál es el plazo de contratación?",
      answer: (
         <Stack direction={"column"} spacing={1}>
            <Typography>
               Los plazos de contratación se adaptan a las necesidades de su organización.
            </Typography>
         </Stack>
      ),
      list: [],
   },
];

export const GovernanceQuestions = [
   {
      title: "question1",
      question: "¿Cuál es la importancia de incorporar un sistema de gobierno corporativo?",
      answer: (
         <Stack direction={"column"} spacing={1}>
            <Typography>
               La implementación del módulo de Gobierno Corporativo de LECOSY se revela como una herramienta fundamental
               para elevar considerablemente el rendimiento de la estructura de gobierno de su empresa. Al mismo tiempo,
               facilita un control más efectivo, contribuyendo así a la mejora sustancial en la toma de decisiones
               estratégicas.
            </Typography>
            <Typography>
               El desafío inherente al Gobierno Corporativo radica comúnmente en las complejidades de la planificación y
               organización. Los miembros que integran los órganos suelen desempeñar roles con numerosas
               responsabilidades, lo que complica la ejecución de funciones estratégicas y el seguimiento de acuerdos.
               La incorporación de nuestro sistema aborda estas dificultades, ofreciendo una solución integral que
               optimiza la eficacia en la gestión corporativa.
            </Typography>
            <Typography>
               Además, contar con este sistema no solo representa una solución a las complejidades organizativas, sino
               que también se traduce en una mejora sustancial en la gestión documental estratégica de la(s) empresa(s).
               La capacidad del sistema para centralizar la información en cualquier lugar y momento facilita su acceso
               y consulta, elevando así la eficiencia y la fluidez en la toma de decisiones informadas.
            </Typography>
            <Typography>
               La incorporación del sistema de gobierno corporativo no solo resuelve obstáculos logísticos, sino que
               también promueve una gestión más eficiente y proactiva de los recursos estratégicos de su organización.
            </Typography>
         </Stack>
      ),
      list: [],
   },
   {
      title: "question2",
      question: "¿A quién va dirigido el sistema de gobierno corporativo de LECOSY?",
      answer: (
         <Stack direction={"column"} spacing={1}>
            <Typography>
               Este módulo se destina a todas las organizaciones que ya poseen una estructura de Gobierno Corporativo o
               que planean implementarla con el objetivo de potenciar la gestión integral de sus empresas. Su utilidad
               abarca diversas industrias y sectores, siendo adaptable a las necesidades particulares de cada entidad.
            </Typography>
            <Typography>
               Es de especial importancia para aquellas sociedades que, por exigencias legales, deben operar bajo un
               Consejo de Administración. Este requisito es particularmente aplicable a las sociedades anónimas
               promotoras de inversión. Sin embargo, el sistema de gobierno corporativo de LECOSY se diseñó con
               flexibilidad para beneficiar a organizaciones de diversos tamaños y estructuras, adaptándose a las
               dinámicas específicas de cada entidad.
            </Typography>
         </Stack>
      ),
      list: [],
   },
   {
      title: "question3",
      question:
         "¿Es posible adquirir el sistema si aún no tengo establecida una estructura de Gobierno Corporativo en mi empresa?",
      answer: (
         <Stack direction={"column"} spacing={1}>
            <Typography>
               Incluso si aún no cuenta con una estructura de Gobierno Corporativo, nuestro sistema puede ser una parte
               valiosa de su trayectoria, y estamos aquí para apoyarlo en el proceso completo, desde la creación hasta
               la gestión continua.
            </Typography>
            <Typography>
               LECOSY está diseñado para gestionar una estructura de Gobierno Corporativo que ya esté previamente
               establecida e implementada en las sociedades; sin embargo, contamos con socios estratégicos altamente
               especializados en el diseño e implementación de estructuras de Gobierno Corporativo para todo tipo de
               empresas.
            </Typography>
            <Typography>
               La colaboración con consultores en la materia, asegurará una adaptación eficiente del sistema a las
               necesidades específicas de su empresa y contribuirá significativamente al establecimiento de un Gobierno
               Corporativo sólido y efectivo.
            </Typography>
         </Stack>
      ),
      list: [],
   },
   {
      title: "question4",
      question: "¿Cuánto tiempo lleva el proceso de implementación del sistema?",
      answer: (
         <Stack direction={"column"} spacing={1}>
            <Typography>
               La duración del proceso de implementación está estrechamente vinculada a la complejidad de la estructura
               de gobierno existente en la organización. Factores como el número de miembros, la cantidad de órganos de
               gobierno y las políticas establecidas impactan directamente en la extensión del proceso.
            </Typography>
            <Typography>
               Nuestro equipo está listo para analizar a fondo su situación y proporcionarle un cronograma detallado que
               refleje con precisión los requerimientos y las particularidades de su estructura de gobierno.
            </Typography>
         </Stack>
      ),
      list: [],
   },
   {
      title: "question5",
      question: "¿Ofrecen capacitación para el uso del software?",
      answer: (
         <Stack direction={"column"} spacing={1}>
            <Typography>
               En LECOSY, nos comprometemos a asegurar que los usuarios del sistema comprendan a fondo y se familiaricen
               con las funcionalidades del módulo. Contamos con procesos y herramientas diseñados específicamente para
               facilitar una transición sin contratiempos hacia la gestión efectiva de su estructura de gobierno.
            </Typography>
            <Typography>
               Nuestros asesores están disponibles para brindar asistencia telefónica en caso de cualquier duda durante
               el proceso de capacitación. La duración de la capacitación variará según la complejidad y el número de
               usuarios en su sistema, asegurando una atención personalizada y eficiente.
            </Typography>
            <Typography>
               Nuestro enfoque integral de capacitación está diseñado para garantizar que aproveche al máximo las
               capacidades del software de gobierno corporativo de LECOSY.
            </Typography>
         </Stack>
      ),
      list: [],
   },
];

export const ComplianceProgramQuestions = [
   {
      title: "question1",
      question: "¿A quién va dirigido el módulo de programa de cumplimiento?",
      answer: "",
      list: [],
   },
   {
      title: "question2",
      question: "¿La implementación de un programa de cumplimiento es una obligación que debo cumplir?",
      answer: "",
      list: [],
   },
   {
      title: "question3",
      question: "¿Cuánto tiempo lleva el proceso de implementación del sistema?",
      answer: "",
      list: [],
   },
   {
      title: "question4",
      question: "¿Ofrecen capacitación para el uso del software?",
      answer: "",
      list: [],
   },
   {
      title: "question5",
      question: "¿Cómo aseguro que realmente tenga cumplimiento a través de esta plataforma de gestión?",
      answer: "",
      list: [],
   },
];
