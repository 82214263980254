import { Box, Stack, Typography } from "@mui/material";

const CompromisesList = ({ description }) => {
   return (
      <Box>
         <Stack direction={"row"} py={1} spacing={0.5}>
            <Box
               minWidth={"20px"}
               minHeight={"20px"}
               maxWidth={"20px"}
               maxHeight={"20px"}
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Programa+de+cumplimiento.svg)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
               }}
            />
            <Box>
               <Typography textAlign={"justify"} fontFamily={"bold"} color={"#162c44"}>
                  {description}
               </Typography>
            </Box>
         </Stack>
      </Box>
   );
};

export default CompromisesList;
