import { Box, Typography } from "@mui/material";
import { ThemeConfig } from "../../../../config/theme.config.js";
import { NavBar } from "../../../../components/NavBar.jsx";

export const GovernanceFormHeader = () => {
   return (
      <ThemeConfig>
         <Box sx={{ width: "100%", padding: 0, borderRadius: 2 }}>
            <NavBar />
            <Box
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/bg-landing-form.png)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "100vh",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  flexDirection: "column",
                  padding: { xs: 2, md: 10 },
               }}
            >
               <Box
                  sx={{
                     paddingX: { xs: 2, md: 2 },
                     paddingTop: { xs: 10, md: 20 },
                     textAlign: { xs: "center", md: "start" },
                  }}
               >
                  <Typography
                     variant="h3"
                     sx={{
                        color: "#FFFFFF",
                        mb: { xs: 1, md: 2 },
                        fontSize: { xs: "2.5rem", md: "3rem" },
                     }}
                  >
                     <strong> Reciba un análisis gratuito </strong>
                     <br />
                     acerca de su
                     <br />
                     <Typography
                        component="span"
                        sx={{
                           fontSize: { xs: "3rem", md: "5rem" },
                           display: "inline-block",
                        }}
                     >
                        <strong> gobernanza empresarial</strong>
                     </Typography>
                  </Typography>

                  <svg
                     width="100%"
                     height="10"
                     viewBox="0 0 813 5"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                     style={{ marginBottom: "16px" }}
                  >
                     <rect width="100%" height="5" fill="url(#paint0_linear_3476_524)" />
                     <defs>
                        <linearGradient
                           id="paint0_linear_3476_524"
                           x1="0"
                           y1="2.5"
                           x2="813"
                           y2="2.5"
                           gradientUnits="userSpaceOnUse"
                        >
                           <stop offset="0.0793503" stopColor="#8A95A1" />
                           <stop offset="0.462164" stopColor="#E8ECEF" />
                           <stop offset="1" stopColor="white" />
                        </linearGradient>
                     </defs>
                  </svg>

                  <Typography
                     variant="h6"
                     sx={{
                        color: "#FFFFFF",
                        paddingRight: { xs: 0, md: 50 },
                        fontSize: { xs: "1rem", md: "1.25rem" },
                     }}
                  >
                     Con nuestro <strong>diagnóstico</strong>, podrá evaluar y optimizar sus{" "}
                     <strong>prácticas actuales</strong>, mientras que nuestra{" "}
                     <strong>consultoría personalizada</strong> le brindará las <strong>herramientas necesarias</strong>
                     para implementar <strong>cambios significativos</strong>.
                  </Typography>
               </Box>
            </Box>
         </Box>
      </ThemeConfig>
   );
};
