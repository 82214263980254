import { Box, Divider, Slide, Stack, Typography } from "@mui/material";
import ContactConsultantButton from "./contactConsultantButton";
import propTypes from "prop-types";

const ServiceTitleSection = ({ title, phrase, icon, zIndex = 1 }) => {
  return (
    <Stack
      direction={"column"}
      spacing={{ xs: 3, lg: 10 }}
      sx={{ pt: { xs: 5, md: 35 }, pb: { xs: 5, md: 10 }, px: "5%" }}
      zIndex={zIndex}
    >
      <Stack direction={"row"} spacing={1} pl={{ lg: 1 }} overflow={"hidden"}>
        <Slide direction="right" in={true} appear timeout={500}>
          <Stack direction={"row"} spacing={1}>
            <Box
              width={{ xs: "0", lg: "10px" }}
              height={"120px"}
              bgcolor={"#162c44"}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                fontFamily={"bold"}
                color={"primary"}
                sx={{ fontSize: { xs: "35px", md: "50px" } }}
              >
                {title}
              </Typography>
              <Divider sx={{ bgcolor: "#8A95A1", borderBottomWidth: "5px" }} />
              <Typography
                color={"primary"}
                sx={{ fontSize: { xs: "20px", md: "25px" } }}
              >
                {phrase}
              </Typography>
            </Box>
          </Stack>
        </Slide>
        <Box
          width={{ xs: "160px", md: "140px", lg: "120px" }}
          height={{ xs: "100px", md: "140px", lg: "120px" }}
          sx={{
            backgroundImage: icon,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            backgroundPosition: "center center",
          }}
        />
      </Stack>
      <Box pl={{ lg: 1 }}>
        <ContactConsultantButton />
      </Box>
    </Stack>
  );
};

ServiceTitleSection.propTypes = {
  title: propTypes.string.isRequired,
  phrase: propTypes.string.isRequired,
  icon: propTypes.string.isRequired,
  zIndex: propTypes.number.isRequired,
};

export default ServiceTitleSection;
