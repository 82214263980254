import { Stack, Typography, Box } from "@mui/material";

export const ComplaintPlatformButton = () => {
   return (
      <Stack
         justifyContent={"center"}
         direction={{ xs: "column", md: "row" }}
         alignItems={"center"}
         py={7}
         sx={{ bgcolor: "#F7F7F7" }}
      >
         <Box
            sx={{
               width: "272px",
               height: "200px",
               backgroundImage:
                  "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Canal+de+denuncia.svg)",
               backgroundRepeat: "no-repeat",
               backgroundPosition: "center center",
            }}
         />
         <Stack direction={"column"} px={5} pt={{ xs: 2, md: 0 }}>
            <Typography
               color={"#FF4F5B"}
               fontFamily={"ExtraBold"}
               fontSize={26}
               sx={{ textAlign: { xs: "center", md: "justify" } }}
            >
               ¡AYÚDENOS A MEJORAR!
            </Typography>
            <Typography fontSize={18} fontFamily={"bold"} sx={{ textAlign: { xs: "center", md: "justify" } }}>
               ¿Tiene alguna denuncia, queja o sugerencia para Lecosy?
            </Typography>
            <Typography textAlign={{ xs: "center", md: "left" }}>
               acceda a nuestro&nbsp;{""}
               <a
                  href="https://denuncias.lecosy.com.mx/lecosy"
                  rel="noreferrer"
                  style={{
                     textDecoration: "none",
                     color: "#162c44",
                  }}
               >
                  <Typography display={"inline"} fontFamily={"bold"} sx={{ "&:hover": { color: "#5c6c7c" } }}>
                     Canal de denuncias.
                  </Typography>
               </a>
            </Typography>
            <Typography sx={{ textAlign: { xs: "center", md: "justify" } }}>
               En Lecosy garantizamos un ambiente seguro, confiable y saludable.
            </Typography>
         </Stack>
      </Stack>
   );
};
