import * as yup from "yup";

const expression = {
   onlyNumbers: /^\d+$/,
};

const textValidation = yup
   .string()
   .min(2, "Se requiere un mínimo de 2 caracteres.")
   .max(100, "Se requiere un máximo de 50 caracteres.")
   .required("Este campo es obligatorio");

const optionalTextValidation = yup
   .string()
   .min(2, "Se requiere un mínimo de 2 caracteres.")
   .max(100, "Se requiere un máximo de 50 caracteres.");

const emailValidation = yup
   .string()
   .min(3, "Se requiere un mínimo de 3 caracteres.")
   .max(50, "Se requiere un máximo de 50 caracteres.")
   .required("Este campo es obligatorio")
   .email("Correo electrónico inválido");

const longTextValidation = yup
   .string()
   .min(2, "Se requiere un mínimo de 2 caracteres.")
   .max(500, "Se requiere un máximo de 200 caracteres.")
   .required("Este campo es obligatorio");

const phoneNumberValidation = yup
   .string()
   .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
   .min(10, "Se requiere exactamente 10 números.")
   .max(10, "Se requiere exactamente 10 números.")
   .required("Este campo es obligatorio");

const optionalPhoneNumberValidation = yup
   .string()
   .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
   .min(10, "Se requiere exactamente 10 números.")
   .max(10, "Se requiere exactamente 10 números.");

export const emailSchema = (isQuestion) => {
   return yup.object().shape({
      name: isQuestion ? optionalTextValidation : textValidation,
      lastName: isQuestion ? optionalTextValidation : textValidation,
      charge: optionalTextValidation,
      phoneNumber: isQuestion ? optionalPhoneNumberValidation : phoneNumberValidation,
      email: emailValidation,
      company: optionalTextValidation,
      ecAct: isQuestion ? optionalTextValidation : textValidation,
      message: longTextValidation,
      checked: yup.boolean().oneOf([true], "Debes aceptar el aviso de privacidad."),
   });
};
