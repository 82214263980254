import { useState, useEffect } from "react";

export const VisibleElement = (element, rootMargin) => {
   const [isVisible, setState] = useState(false);
   useEffect(() => {
      const observer = new IntersectionObserver(
         ([entry]) => {
            setState(entry.isIntersecting);
         },
         { rootMargin }
      );

      element.current && observer.observe(element.current);
   }, [element, rootMargin]);

   return isVisible;
};
