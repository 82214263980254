export const respuestas = [
 { field: "rolEmpresa", value: "accionista", categoria: "A" },
 { field: "rolEmpresa", value: "alta-direccion", categoria: "B" },
 { field: "rolEmpresa", value: "consejero", categoria: "A" },
 {
   field: "rolEmpresa",
   value: "miembro-familia-empresaria",
   categoria: "A",
 },
 { field: "rolEmpresa", value: "operativo", categoria: "C" },
 {
   field: "sectorEmpresa",
   value: "arrendamiento-bienes-inmuebles",
   categoria: "A",
 },
 { field: "sectorEmpresa", value: "comercio-exterior", categoria: "A" },
 { field: "sectorEmpresa", value: "comercio-metales-joyas", categoria: "A" },
 { field: "sectorEmpresa", value: "comercio-otros", categoria: "B" },
 {
   field: "sectorEmpresa",
   value: "desarrollo-bienes-inmuebles",
   categoria: "A",
 },
 { field: "sectorEmpresa", value: "distribucion-vehiculos", categoria: "A" },
 { field: "sectorEmpresa", value: "educacion", categoria: "B" },
 { field: "sectorEmpresa", value: "energia", categoria: "B" },
 {
   field: "sectorEmpresa",
   value: "industria-manufacturera",
   categoria: "B",
 },
 {
   field: "sectorEmpresa",
   value: "mutuos-prestamos-creditos",
   categoria: "A",
 },
 { field: "sectorEmpresa", value: "otro-especificar", categoria: "B" },
 { field: "sectorEmpresa", value: "salud", categoria: "B" },
 { field: "sectorEmpresa", value: "sector-primario", categoria: "B" },
 {
   field: "sectorEmpresa",
   value: "servicios-inmobiliarios",
   categoria: "B",
 },
 {
   field: "sectorEmpresa",
   value: "servicios-profesionales",
   categoria: "A",
 },
 { field: "sectorEmpresa", value: "telecomunicaciones", categoria: "B" },
 { field: "sectorEmpresa", value: "tecnologia-informacion", categoria: "B" },
 { field: "sectorEmpresa", value: "transporte-logistica", categoria: "B" },
 {
   field: "sectorEmpresa",
   value: "traslado-custodia-valores",
   categoria: "A",
 },
 { field: "sectorEmpresa", value: "turismo-hosteleria", categoria: "B" },
 { field: "tamanoEmpresa", value: "entre-10-50-millones", categoria: "C" },
 { field: "tamanoEmpresa", value: "entre-100-200-millones", categoria: "B" },
 { field: "tamanoEmpresa", value: "entre-200-400-millones", categoria: "A" },
 { field: "tamanoEmpresa", value: "entre-50-100-millones", categoria: "B" },
 { field: "tamanoEmpresa", value: "hasta-10-millones", categoria: "C" },
 { field: "tamanoEmpresa", value: "mas-de-400-millones", categoria: "A" },
 { field: "numeroEmpleados", value: "1-19", categoria: "C" },
 { field: "numeroEmpleados", value: "101-500", categoria: "A" },
 { field: "numeroEmpleados", value: "20-50", categoria: "C" },
 { field: "numeroEmpleados", value: "501-o-mas", categoria: "A" },
 { field: "numeroEmpleados", value: "51-100", categoria: "B" },
 { field: "aniosMercado", value: "0-3", categoria: "C" },
 { field: "aniosMercado", value: "11-30", categoria: "B" },
 { field: "aniosMercado", value: "31-o-mas", categoria: "A" },
 { field: "aniosMercado", value: "4-10", categoria: "C" },
 { field: "empresaFamiliar", value: "si", categoria: "A" },
 { field: "empresaFamiliar", value: "no", categoria: "B" },
 { field: "etapaEmpresa", value: "validacion", categoria: "C" },
 { field: "etapaEmpresa", value: "constitucion", categoria: "C" },
 { field: "etapaEmpresa", value: "consolidacion", categoria: "B" },
 { field: "etapaEmpresa", value: "expansion", categoria: "A" },
 { field: "tipoSociedad", value: "ac", categoria: "B" },
 { field: "tipoSociedad", value: "sab", categoria: "A" },
 { field: "tipoSociedad", value: "sa", categoria: "B" },
 { field: "tipoSociedad", value: "sapib", categoria: "A" },
 { field: "tipoSociedad", value: "sapi", categoria: "A" },
 { field: "tipoSociedad", value: "sca", categoria: "B" },
 { field: "tipoSociedad", value: "sc", categoria: "B" },
 { field: "tipoSociedad", value: "scoop", categoria: "B" },
 { field: "tipoSociedad", value: "sas", categoria: "B" },
 { field: "tipoSociedad", value: "scs", categoria: "B" },
 { field: "tipoSociedad", value: "snc", categoria: "B" },
 { field: "tipoSociedad", value: "ss", categoria: "B" },
 // Preguntas del Paso 2
 { field: "decisionesColegiadas", value: "1", categoria: "A" },
 { field: "decisionesColegiadas", value: "2", categoria: "B" },
 { field: "decisionesColegiadas", value: "3", categoria: "B" },
 { field: "decisionesColegiadas", value: "4", categoria: "C" },
 { field: "decisionesColegiadas", value: "5", categoria: "C" },
 { field: "riesgosDefinidos", value: "1", categoria: "A" },
 { field: "riesgosDefinidos", value: "2", categoria: "B" },
 { field: "riesgosDefinidos", value: "3", categoria: "B" },
 { field: "riesgosDefinidos", value: "4", categoria: "C" },
 { field: "riesgosDefinidos", value: "5", categoria: "C" },
 { field: "inversionesExternas", value: "1", categoria: "A" },
 { field: "inversionesExternas", value: "2", categoria: "B" },
 { field: "inversionesExternas", value: "3", categoria: "B" },
 { field: "inversionesExternas", value: "4", categoria: "C" },
 { field: "inversionesExternas", value: "5", categoria: "C" },
 { field: "riesgosOperacionales", value: "1", categoria: "A" },
 { field: "riesgosOperacionales", value: "2", categoria: "B" },
 { field: "riesgosOperacionales", value: "3", categoria: "B" },
 { field: "riesgosOperacionales", value: "4", categoria: "C" },
 { field: "riesgosOperacionales", value: "5", categoria: "C" },
 { field: "problemasGestionInterna", value: "1", categoria: "A" },
 { field: "problemasGestionInterna", value: "2", categoria: "B" },
 { field: "problemasGestionInterna", value: "3", categoria: "B" },
 { field: "problemasGestionInterna", value: "4", categoria: "C" },
 { field: "problemasGestionInterna", value: "5", categoria: "C" },
 { field: "competenciaMercado", value: "1", categoria: "A" },
 { field: "competenciaMercado", value: "2", categoria: "B" },
 { field: "competenciaMercado", value: "3", categoria: "B" },
 { field: "competenciaMercado", value: "4", categoria: "C" },
 { field: "competenciaMercado", value: "5", categoria: "C" },
 { field: "planSucesion", value: "1", categoria: "A" },
 { field: "planSucesion", value: "2", categoria: "B" },
 { field: "planSucesion", value: "3", categoria: "B" },
 { field: "planSucesion", value: "4", categoria: "C" },
 { field: "planSucesion", value: "5", categoria: "C" },
 { field: "problemasComunicacion", value: "1", categoria: "A" },
 { field: "problemasComunicacion", value: "2", categoria: "B" },
 { field: "problemasComunicacion", value: "3", categoria: "B" },
 { field: "problemasComunicacion", value: "4", categoria: "C" },
 { field: "problemasComunicacion", value: "5", categoria: "C" },
 { field: "tecnologiasModernas", value: "1", categoria: "A" },
 { field: "tecnologiasModernas", value: "2", categoria: "B" },
 { field: "tecnologiasModernas", value: "3", categoria: "B" },
 { field: "tecnologiasModernas", value: "4", categoria: "C" },
 { field: "tecnologiasModernas", value: "5", categoria: "C" },
 { field: "problemasControlFinanciero", value: "1", categoria: "A" },
 { field: "problemasControlFinanciero", value: "2", categoria: "B" },
 { field: "problemasControlFinanciero", value: "3", categoria: "B" },
 { field: "problemasControlFinanciero", value: "4", categoria: "C" },
 { field: "problemasControlFinanciero", value: "5", categoria: "C" },
];