import { Box, Divider, Stack } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";
import PropTypes from "prop-types";
import React from "react";

const ImageContainer = ({
  imageUrl,
  direction = "row",
  title,
  subTitle,
  subComponent,
  alternComponent,
  imageProps,
}) => {
  return (
    <Stack direction={"column"} spacing={2} py={5}>
      {title ? (
        <div>
          <Box
            color={"#46566A"}
            display={"flex"}
            minWidth={{ lg: "45%" }}
            maxWidth={{ lg: "45%" }}
            justifyContent={"flex-end"}
          >
            {title}
          </Box>
          <AnimationOnScroll
            animateIn="animate__slideInLeft"
            duration={0.75}
            animatePreScroll={false}
            animateOnce
          >
            <Divider
              sx={{ borderBottomWidth: "4px", maxWidth: "45%", bgcolor: "#46566A" }}
              minWidth={{ lg: "45%" }}
            />
          </AnimationOnScroll>
        </div>
      ) : undefined}
      <Stack
        direction={{ xs: direction === "row" ? "column" : "column-reverse", md: direction }}
        spacing={5}
        py={5}
      >
        <Stack direction={"column"} alignItems={"flex-end"} spacing={1} flex={1}>
          {subTitle}
          <Box {...imageProps}>
            <Box
              component="img"
              src={imageUrl}
              alt="Image Display"
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
            <Box overflow={"hidden"}>
              <AnimationOnScroll
                animateIn="animate__slideInLeft"
                animateOnce
                animatePreScroll={false}
                duration={0.75}
                initiallyVisible
              >
                <Divider sx={{ borderBottomWidth: 5, bgcolor: "#8A95A1", mt: 1 }} />
              </AnimationOnScroll>
            </Box>
          </Box>
          <Box py={4} width={"100%"} minHeight={"150px"}>
            {subComponent}
          </Box>
        </Stack>
        <Box flex={1}>{alternComponent}</Box>
      </Stack>
    </Stack>
  );
};

ImageContainer.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  title: PropTypes.element,
  subTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  subComponent: PropTypes.element,
  alternComponent: PropTypes.element,
  imageProps: PropTypes.object,
};

export default ImageContainer;
