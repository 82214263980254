const fieldRolMap = {
  "alta-direccion": "Alta Dirección",
  "consejero": "Consejero",
  "miembro-familia-empresaria": "Miembro de la Familia Empresaria",
  "operativo": "Operativo",
  "accionista": "Accionista",
};

const fieldSizeMap = {
  "hasta-10-millones": "Microempresa",
  "entre-10-50-millones": "Pequeña Empresa",
  "entre-50-100-millones": "Mediana Empresa",
  "entre-100-200-millones": "Mediana Empresa",
  "entre-200-400-millones": "Gran Empresa",
  "mas-de-400-millones": "Corporación",
};

/**
 * Retrieves the corresponding text for the company role based on its value.
 * 
 * @param {string} rolEmpresa - The value representing the company role.
 * @returns {string} The corresponding text for the company role.
 */
export const getRolForField = (rolEmpresa) => {
  const result = fieldRolMap[rolEmpresa] || "Rol no definido";
  return result;
};

/**
 * Retrieves the corresponding text for the company size based on its value.
 * 
 * @param {string} tamanoEmpresa - The value representing the company size.
 * @returns {string} The corresponding text for the company size.
 */
export const getSizeForField = (tamanoEmpresa) => {
  const result = fieldSizeMap[tamanoEmpresa] || "Tamaño de empresa no definido";
  return result;
};
