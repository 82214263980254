import { Box, Button, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ContactConsultantButton = () => {
   return (
      <Button
         href="https://calendar.app.google/KcrgoEnbz9SJScGv8"
         target={"_blank"}
         sx={{ borderRadius: 3, p: 1, "&:hover": { bgcolor: "#5c6c7c" } }}
      >
         <Box sx={{ display: "flex", alignItems: "center" }} mx={1}>
            <Typography fontWeight={"bold"}>Contactar a consultor</Typography>
            <ArrowForwardIcon fontSize={"small"} sx={{ ml: 0.5 }} />
         </Box>
      </Button>
   );
};

export default ContactConsultantButton;
