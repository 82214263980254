import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const StyledAccordion = ({
  question,
  answer,
  selectedAnswer,
  setSelectedAnswer,
  setSelectedVideo,
  videoUrl,
}) => {
  const handleClick = () => {
    setSelectedVideo(videoUrl);
    setSelectedAnswer(answer);
  };

  return (
    <Stack spacing={0.2}>
      <Button
        className={selectedAnswer === answer ? "gradientBox904" : undefined}
        onClick={handleClick}
        onTouchStart={handleClick}
        style={{
          backgroundColor: "#E8ECEF",
          padding: "8px 0",
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
          width: "100%",
          textTransform: "none",
        }}
      >
        <Stack
          spacing={4}
          direction={"row"}
          width={"100%"}
          justifyContent={"space-between"}
          px={3}
        >
          <Typography
            fontFamily={"ExtraBold"}
            pl={8}
            fontSize={selectedAnswer === answer ? "20px" : "16px"}
            color={"#162c44"}
          >
            {question}
          </Typography>
          <ChevronRightIcon
            sx={{
              alignSelf: "center",
              display: selectedAnswer === answer ? "none" : "block",
              color: "#162c44",
            }}
          />
        </Stack>
      </Button>
    </Stack>
  );
};

export const StyledAccordionComponent = ({
  title,
  mainTitleContent,
  mainVideoContent,
  content,
  selectedAnswer,
  setSelectedAnswer,
  setSelectedVideo,
}) => {
  const handleClick = () => {
    setSelectedVideo(mainVideoContent);
    setSelectedAnswer(mainTitleContent);
  };
  return (
    <Stack direction={"column"}>
      <Button
        onClick={() => {
          handleClick();
        }}
        onTouchStart={() => {
          handleClick();
        }}
        className={
          selectedAnswer.key === "mainTitleContent"
            ? "gradientBox904"
            : undefined
        }
        sx={{
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
          "&:hover": { cursor: "pointer" },
        }}
        maxWidth={{ lg: "70%" }}
        display={"flex"}
        justifyContent={"space-between"}
        px={3}
        bgcolor={"#E8ECEF"}
        style={{
          backgroundColor: "#E8ECEF",
          padding: "8px 0",
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
          width: "100%",
          textTransform: "none",
        }}
      >
        <Typography
          fontFamily={"ExtraBold"}
          py={1}
          pl={8}
          fontSize={selectedAnswer.key === "mainTitleContent" ? "20px" : "16px"}
          color={"#162c44"}
        >
          {title}
        </Typography>
        <ChevronRightIcon
          sx={{
            alignSelf: "center",
            display:
              selectedAnswer.key === "mainTitleContent" ? "none" : "block",
            color: "#162c44",
          }}
        />
      </Button>
      <Divider
        sx={{
          bgcolor: "#162c44",
          width: { xs: "95%", lg: "68%" },
          borderBottomWidth: "3px",
        }}
      />
      <Stack
        direction={"column"}
        py={0.75}
        spacing={0.75}
        maxWidth={{ lg: "70%" }}
      >
        {content.map((e) => {
          return (
            <StyledAccordion
              key={e.question}
              {...e}
              selectedAnswer={selectedAnswer}
              setSelectedAnswer={setSelectedAnswer}
              setSelectedVideo={setSelectedVideo}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

StyledAccordionComponent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  selectedAnswer: PropTypes.string,
  setSelectedAnswer: PropTypes.func,
  setSelectedVideo: PropTypes.func,
  mainTitleContent: PropTypes.string,
  mainVideoContent: PropTypes.string,
};

StyledAccordion.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  selectedAnswer: PropTypes.string,
  setSelectedAnswer: PropTypes.func,
  setSelectedVideo: PropTypes.func,
  videoUrl: PropTypes.string,
};
