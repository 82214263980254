import { Box } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useEffect, useState } from "react";

export const ScrollToTopButton = (window) => {
   const [topScreen, setTopScreen] = useState(false);

   useEffect(() => {
      window.onscroll = () =>
         (window.pageYOffset > 400 && setTopScreen(true)) || (window.pageYOffset < 200 && setTopScreen(false));
      return () => (window.onscroll = null);
   });

   return (
      <Box
         zIndex={10}
         onClick={() => {
            window.scrollTo(0, 0);
            setTopScreen(false);
         }}
         sx={{
            position: "fixed",
            right: 40,
            bottom: 40,
            height: "45px",
            width: "45px",
            borderRadius: "100px",
            border: 2,
            borderColor: "white",
            bgcolor: "#162c44",
            color: "white",
            display: topScreen ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
               cursor: "pointer",
            },
         }}
      >
         <ExpandLessIcon />
      </Box>
   );
};
