import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { createTheme } from "@mui/material/styles";

// Crear un tema personalizado
const theme = createTheme({
    palette: {
        primary: {
            main: "#162C44",
        },
        secondary: {
            main: "#000000",
        },
        background: {
            default: "#E8ECEF",
        },
    },
    typography: {
        fontFamily: "'Open Sans', sans-serif",  // Open Sans para todo el sitio
        h1: {
            fontFamily: "'Open Sans', sans-serif",
        },
        h2: {
            fontFamily: "'Open Sans', sans-serif",
        },
        h3: {
            fontFamily: "'Open Sans', sans-serif",
        },
        h4: {
            fontFamily: "'Open Sans', sans-serif",
        },
        h5: {
            fontFamily: "'Open Sans', sans-serif",
        },
        h6: {
            fontFamily: "'Open Sans', sans-serif",
        },
        body1: {
            fontFamily: "'Open Sans', sans-serif",
        },
        body2: {
            fontFamily: "'Open Sans', sans-serif",
        },
        subtitle1: {
            fontFamily: "'Open Sans', sans-serif",
        },
        subtitle2: {
            fontFamily: "'Open Sans', sans-serif",
        },
        button: {
            fontFamily: "'Open Sans', sans-serif",
        },
        caption: {
            fontFamily: "'Open Sans', sans-serif",
        },
        overline: {
            fontFamily: "'Open Sans', sans-serif",
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "12px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.4)",
                    "& .MuiFilledInput-root": {
                        borderRadius: "12px",
                        boxShadow: "none",
                        "&:before, &:after": {
                            borderBottom: "none !important",
                        },
                        "&:hover:not(.Mui-disabled):before": {
                            borderBottom: "none !important",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "none !important",
                        },
                    },
                    "& .MuiInputBase-input": {
                        color: "#162C44",
                    },
                    "& .MuiInputLabel-root": {
                        color: "#162C44",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                        color: "#162C44",
                    },
                    "& .MuiInputBase-input::placeholder": {
                        color: "#162C44",
                        opacity: 1,
                    },
                    "& .Mui-focused .MuiInputBase-input::placeholder": {
                        color: "#162C44",
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontFamily: "'Open Sans', sans-serif",
                    borderRadius: "12px",
                    backgroundColor: "#162C44",
                    color: "white",
                    "&:hover": {
                        backgroundColor: "#0F1F33",
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                subtitle1: {
                    color: "#162C44",
                    fontWeight: "600",
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    "&.Mui-active, &.Mui-completed": {
                        color: "#162C44",
                        backgroundColor: "#E8ECEF",
                        borderRadius: "50%",
                        padding: "8px",
                    },
                },
            },
        },
    },
});

export const ThemeConfig = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};
