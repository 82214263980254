import React, { useState, useEffect } from "react";
import { NavBar, Footer } from "../../index.js";
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Modal,
} from "@mui/material";
import { ThemeConfig } from "../../../config/theme.config.js";
import ScrollToTop from "../../../wrapper/scrollToTop.jsx";
import { getSummaryByCategory } from "../../../utils/summaries.js";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CustomProgress } from "./components/percentageIcon.svg";
import SummaryCard from "./components/SummaryCard.js";
import {
  getRolForField,
  getSizeForField,
} from "../../../utils/pdfHelpers.js";
import { useEmail } from "../EmailContext.jsx";
import { sendMailRequest } from "../../../lib/lecosyBackend.js";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", md: 400 },
  bgcolor: "#E8ECEF",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  outline: "none",
};

export const ResultsScreen = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const formData = location.state?.formData || [];
  const { email } = useEmail();

  useEffect(() => {
    if (!formData.length) {
      navigate("/landing-page/form");
    } else if (!email) {
      navigate("/landing-page");
    }
  }, [formData, email, navigate]);

  const sectorEmpresaCategory = formData.find((item) => item.field === "sectorEmpresa")?.categoria || "A";
  const numeroEmpleadosCategory = formData.find((item) => item.field === "numeroEmpleados")?.categoria || "B";
  const tipoSociedadCategory = formData.find((item) => item.field === "tipoSociedad")?.categoria || "A";
  const decisionesColegiadasCategory = formData.find((item) => item.field === "decisionesColegiadas")?.categoria || "C";

  // Get summaries based on dynamic categories
  const sectorEmpresaSummary = getSummaryByCategory("sectorEmpresa", sectorEmpresaCategory);
  const numeroEmpleadosSummary = getSummaryByCategory("numeroEmpleados", numeroEmpleadosCategory);
  const tipoSociedadSummary = getSummaryByCategory("tipoSociedad", tipoSociedadCategory);
  const decisionesColegiadasSummary = getSummaryByCategory("decisionesColegiadas", decisionesColegiadasCategory);

  const [open, setOpen] = useState(false);

  const handleSend = async () => {
    setIsButtonDisabled(true);

    const formData = location.state?.formData || [];
    const name =
      formData.find((item) => item.field === "nombre")?.value || "Usuario";

    if (!email) {
      alert("Por favor, ingresa un correo.");
      setIsButtonDisabled(false);
      return;
    }

    if (email) {
      formData.push({ field: "email", value: email });
    }

    openConfirmationModal();

    try {
      await sendMailRequest({ email, formData, name });
    } catch (error) {
      console.error("Error al solicitar el envío:", error);
      alert("Hubo un problema al solicitar el envío.");
      setIsButtonDisabled(false);
    }
  };

  const openConfirmationModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeConfig>
      <ScrollToTop>
        <NavBar />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="confirmation-modal-title"
          aria-describedby="confirmation-modal-description"
        >
          <Box
            sx={{
              ...modalStyle,
              width: { xs: "90%", md: "500px" },
              height: { xs: "auto", md: "300px" },
              borderRadius: "12px",
              p: { xs: 2, md: 4 },
            }}
          >
            <Typography
              id="confirmation-modal-title"
              variant="h6"
              component="h2"
              sx={{ color: "#162C44", fontWeight: "bold" }}
            >
              Gracias por completar su diagnóstico de Gobernanza empresarial.
            </Typography>
            <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
              En breve, estará recibiendo su informe completo con
              recomendaciones personalizadas basadas en sus respuestas.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <Button onClick={handleClose} sx={{ width: "120px" }}>
                Aceptar
              </Button>
            </Box>
          </Box>
        </Modal>

        <Grid container sx={{ minHeight: "100vh", backgroundColor: "white" }}>
          {/* Sidebar */}
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              padding: { xs: 2, md: 3 },
              position: "relative",
              borderTopRightRadius: { xs: 0, md: 30 },
              borderBottomRightRadius: { xs: 0, md: 30 },
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                background:
                  "linear-gradient(83.31deg, #162C44 -17.93%, #000000 117.34%)",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1,
                borderTopRightRadius: { xs: 0, md: 30 },
                borderBottomRightRadius: { xs: 0, md: 30 },
              }}
            />

            <Box
              sx={{
                backgroundImage: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/sidebar.svg)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom",
                backgroundSize: "cover",
                width: "100%",
                height: { xs: "80%", md: "90%" },
                position: "absolute",
                bottom: 0,
                left: 0,
                zIndex: 2,
              }}
            />

            <Box
              sx={{
                position: "relative",
                zIndex: 3,
                color: "#fff",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: { xs: 2, md: 4 },
                  mt: { xs: 6, md: 13 },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-flex",
                    background:
                      "linear-gradient(180deg, rgba(232, 236, 239, 0.15) -36.05%, rgba(255, 255, 255, 0.15) 84.68%)",
                    padding: 2,
                    boxShadow: "8px 8px 5.1px rgba(0, 0, 0, 0.25)",
                    borderRadius: "40px",
                    justifyContent: "center",
                  }}
                >
                  <CustomProgress
                    style={{
                      width: "90%",
                      color: "#f1f1f1",
                    }}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#f1f1f1",
                    }}
                  >
                    <Typography
                      variant="h2"
                      component="div"
                      color="inherit"
                      fontWeight={"bold"}
                    >
                      100%
                    </Typography>
                    <Typography variant="h6" component="div" color="inherit">
                      Completado
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ mt: 6, textAlign: "center", position: "relative" }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mx: 3 }}>
                    <img
                      src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/user.svg"
                      alt="User Icon"
                      style={{
                        width: "64px",
                        height: "64px",
                        marginRight: "12px",
                      }}
                    />
                  </Box>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {formData.find((item) => item.field === "nombre")?.value ||
                      "Usuario"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "4px",
                    backgroundColor: "transparent",
                    borderLeft: "4px dashed white",
                    height: "100px",
                    marginLeft: 7,
                    marginRight: "auto",
                  }}
                />

                <Box sx={{ display: "flex", alignItems: "center", mb: 2, mt: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mx: 3 }}>
                    <img
                      src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/rol.svg"
                      alt="Rol Icon"
                      style={{
                        width: "64px",
                        height: "64px",
                        marginRight: "12px",
                      }}
                    />
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {getRolForField(
                      formData.find((item) => item.field === "rolEmpresa")
                        ?.value || ""
                    )}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "4px",
                    backgroundColor: "transparent",
                    borderLeft: "4px dashed white",
                    height: "100px",
                    marginLeft: 7,
                    marginRight: "auto",
                  }}
                />

                <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mx: 3 }}>
                    <img
                      src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/companySize.svg"
                      alt="Company Size Icon"
                      style={{
                        width: "64px",
                        height: "64px",
                        marginRight: "12px",
                      }}
                    />
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {getSizeForField(
                      formData.find((item) => item.field === "tamanoEmpresa")
                        ?.value || "Tamaño de empresa no definido"
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Main content */}
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              paddingTop: { xs: 6, md: 16 },
              backgroundColor: "#FFFFFF",
              color: "#162C44",
            }}
          >
            <Box>
              <Box sx={{ paddingLeft: { xs: 2, md: 12 }, paddingRight: { xs: 2, md: 16 } }}>
                <Typography
                  variant="h3"
                  sx={{
                    mb: 4,
                    color: "#162C44",
                    fontWeight: "bold",
                    paddingX: 3,
                  }}
                >
                  Hola,{" "}
                  {formData.find((item) => item.field === "nombre")?.value ||
                    "Usuario"}
                </Typography>
                <Typography
                  sx={{
                    mb: 4,
                    fontWeight: "bold",
                    paddingX: 3,
                    textAlign: "justify",
                    fontSize: "22px",
                  }}
                >
                  En base a las respuestas que nos has proporcionado en el
                  cuestionario, hemos elaborado un informe con recomendaciones
                  personalizadas en materia de gobernanza empresarial.
                </Typography>
              </Box>
              <Typography
                sx={{
                  mb: 2,
                  paddingLeft: { xs: 5, md: 15 },
                  paddingY: 2,
                  color: "#748190",
                  backgroundColor: "#E8ECEF",
                  fontWeight: "bold",
                  width: { xs: "100%", md: "60%" },
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  fontSize: "26px",
                }}
              >
                Este es el resumen de sus resultados:
              </Typography>
              <Box />
              <Box sx={{ paddingLeft: { xs: 2, md: 12 }, paddingRight: { xs: 2, md: 16 } }}>
                <Paper sx={{ padding: 3, boxShadow: "none" }}>
                  <SummaryCard
                    title="Debido al sector en el que se desempeña la empresa:"
                    summary={sectorEmpresaSummary}
                  />
                  <SummaryCard
                    title="Por el número de colaboradores:"
                    summary={numeroEmpleadosSummary}
                  />
                  <SummaryCard
                    title="En base al tipo de sociedad:"
                    summary={tipoSociedadSummary}
                  />
                  <SummaryCard
                    title="De acuerdo al proceso de toma de decisiones:"
                    summary={decisionesColegiadasSummary}
                  />
                </Paper>
                <Typography
                  variant="body1"
                  sx={{ mb: 6, paddingX: 3, color: "#8A95A0" }}
                >
                  Los resultados de este diagnóstico se basan en aproximaciones
                  de las respuestas proporcionadas.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                background: "rgba(232, 236, 239, 0.45)",
                paddingLeft: { xs: 2, md: 12 },
                paddingRight: { xs: 2, md: 16 },
                paddingY: 8,
              }}
            >
              <Box
                sx={{
                  paddingX: 3,
                  backgroundColor: "none",
                  boxShadow: "none",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ mb: 2, fontWeight: "bold", color: "#162C44" }}
                >
                  ¿Quiere recibir su informe completo?
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#162C44",
                      height: "auto",
                      paddingY: 1.2,
                      paddingX: 4,
                    }}
                    onClick={handleSend}
                    //disabled={isButtonDisabled}
                  >
                    Enviar por correo electrónico
                  </Button>
                </Box>
              </Box>
              <Typography
                variant="body1"
                sx={{ mb: 4, paddingX: 3, textAlign: "justify" }}
              >
                Para obtener un análisis más preciso y descubrir cómo nuestras
                soluciones pueden optimizar su gobernanza corporativa,{" "}
                <strong>
                  contáctenos para recibir asesoramiento personalizado
                </strong>{" "}
                y adaptado a sus necesidades específicas.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Footer />
      </ScrollToTop>
    </ThemeConfig>
  );
};
