import React from "react";
import { Grid, TextField, Typography, MenuItem } from "@mui/material";
import { Country, State } from "country-state-city";

const StepOneForm = ({ formData, handleChange }) => {
   const selectedCountry = formData.find((item) => item.field === "pais")?.value;
   const estados = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];

   return (
      <>
         {/* Preguntas del Paso 1 */}
         <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               Nombre
            </Typography>
            <TextField
               fullWidth
               label="Texto"
               name="nombre"
               value={formData.find((item) => item.field === "nombre")?.value}
               onChange={handleChange}
               variant="filled"
               inputProps={{ maxLength: 50 }} // Limitar a 50 caracteres
            />
         </Grid>

         <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               Apellido
            </Typography>
            <TextField
               fullWidth
               label="Texto"
               name="apellido"
               value={formData.find((item) => item.field === "apellido")?.value}
               onChange={handleChange}
               variant="filled"
               inputProps={{ maxLength: 50 }} // Limitar a 50 caracteres
            />
         </Grid>

         <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               País
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="pais"
               value={formData.find((item) => item.field === "pais")?.value || ""}
               onChange={handleChange}
               variant="filled"
            >
               {Country.getAllCountries()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((country) => (
                     <MenuItem
                        key={country.isoCode}
                        value={country.isoCode}
                        sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
                     >
                        {country.name}
                     </MenuItem>
                  ))}
            </TextField>
         </Grid>

         <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               Entidad federativa
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="entidadFederativa"
               value={formData.find((item) => item.field === "entidadFederativa")?.value || ""}
               onChange={handleChange}
               disabled={!selectedCountry}
               variant="filled"
            >
               {estados
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((estado) => (
                     <MenuItem
                        key={estado.isoCode}
                        value={estado.isoCode}
                        sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
                     >
                        {estado.name}
                     </MenuItem>
                  ))}
            </TextField>
         </Grid>

         <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               Rol dentro de la empresa
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="rolEmpresa"
               value={formData.find((item) => item.field === "rolEmpresa")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="accionista" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Accionista
               </MenuItem>
               <MenuItem value="alta-direccion" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Alta Dirección
               </MenuItem>
               <MenuItem value="consejero" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Consejero
               </MenuItem>
               <MenuItem
                  value="miembro-familia-empresaria"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Miembro de la Familia Empresaria
               </MenuItem>
               <MenuItem value="operativo" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Operativo
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               Sector de su empresa
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="sectorEmpresa"
               value={formData.find((item) => item.field === "sectorEmpresa")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem
                  value="arrendamiento-bienes-inmuebles"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Arrendamiento de bienes inmuebles
               </MenuItem>
               <MenuItem
                  value="comercio-exterior"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Comercio Exterior
               </MenuItem>
               <MenuItem
                  value="comercio-metales-joyas"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Comercio de metales o joyas
               </MenuItem>
               <MenuItem value="comercio-otros" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Comercio (otros)
               </MenuItem>
               <MenuItem
                  value="desarrollo-bienes-inmuebles"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Desarrollo, comercialización o intermediación de bienes inmuebles
               </MenuItem>
               <MenuItem
                  value="distribucion-vehiculos"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Comercialización o distribución de vehículos
               </MenuItem>
               <MenuItem value="educacion" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Educación
               </MenuItem>
               <MenuItem value="energia" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Energía
               </MenuItem>
               <MenuItem
                  value="industria-manufacturera"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Industria manufacturera
               </MenuItem>
               <MenuItem
                  value="mutuos-prestamos-creditos"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Ofrecimiento de mutuos, préstamos o créditos
               </MenuItem>
               <MenuItem
                  value="otro-especificar"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Otro
               </MenuItem>
               <MenuItem value="salud" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Salud
               </MenuItem>
               <MenuItem
                  value="sector-primario"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Servicios Primario
               </MenuItem>
               <MenuItem
                  value="servicios-inmobiliarios"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Servicios inmobiliarios
               </MenuItem>
               <MenuItem
                  value="servicios-profesionales"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Prestación de servicios profesionales
               </MenuItem>
               <MenuItem
                  value="telecomunicaciones"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Telecomunicaciones
               </MenuItem>
               <MenuItem
                  value="tecnologia-informacion"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Tecnología de la información
               </MenuItem>
               <MenuItem
                  value="transporte-logistica"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Transporte y logística
               </MenuItem>
               <MenuItem
                  value="traslado-custodia-valores"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Traslado o custodia de valores
               </MenuItem>
               <MenuItem
                  value="turismo-hosteleria"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Turismo y hostelería
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               Tamaño de la empresa por ingresos anuales
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="tamanoEmpresa"
               value={formData.find((item) => item.field === "tamanoEmpresa")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem
                  value="hasta-10-millones"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Hasta 10 millones de pesos
               </MenuItem>
               <MenuItem
                  value="entre-10-50-millones"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Entre 10 y 50 millones
               </MenuItem>
               <MenuItem
                  value="entre-50-100-millones"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Entre 50 y 100 millones
               </MenuItem>
               <MenuItem
                  value="entre-100-200-millones"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Entre 100 y 200 millones
               </MenuItem>
               <MenuItem
                  value="entre-200-400-millones"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Entre 200 y 400 millones
               </MenuItem>
               <MenuItem
                  value="mas-de-400-millones"
                  sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}
               >
                  Más de 400 millones
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               Número de empleados
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="numeroEmpleados"
               value={formData.find((item) => item.field === "numeroEmpleados")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="1-19" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  1 a 19
               </MenuItem>
               <MenuItem value="20-50" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  20 a 50
               </MenuItem>
               <MenuItem value="51-100" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  51 a 100
               </MenuItem>
               <MenuItem value="101-500" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  101 a 500
               </MenuItem>
               <MenuItem value="501-o-mas" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  501 o más
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               Años en el mercado
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="aniosMercado"
               value={formData.find((item) => item.field === "aniosMercado")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="0-3" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  0 a 3
               </MenuItem>
               <MenuItem value="4-10" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  4 a 10
               </MenuItem>
               <MenuItem value="11-30" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  11 a 30
               </MenuItem>
               <MenuItem value="31-o-mas" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  31 o más
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               ¿Es una empresa familiar?
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="empresaFamiliar"
               value={formData.find((item) => item.field === "empresaFamiliar")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="si" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Sí
               </MenuItem>
               <MenuItem value="no" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  No
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               ¿En qué etapa se encuentra su empresa?
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="etapaEmpresa"
               value={formData.find((item) => item.field === "etapaEmpresa")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="validacion" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  En etapa de validación del Modelo de negocio
               </MenuItem>
               <MenuItem value="constitucion" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  En etapa de constitución
               </MenuItem>
               <MenuItem value="consolidacion" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  En etapa de consolidación
               </MenuItem>
               <MenuItem value="expansion" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  En etapa de expansión
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               ¿Cuál es el tipo de sociedad de su empresa?
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="tipoSociedad"
               value={formData.find((item) => item.field === "tipoSociedad")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="ac" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Asociación Civil (A.C.)
               </MenuItem>
               <MenuItem value="sab" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Sociedad Anónima Bursátil (S.A.B.)
               </MenuItem>
               <MenuItem value="sa" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Sociedad Anónima (S.A.)
               </MenuItem>
               <MenuItem value="sapib" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Sociedad Anónima Promotora de Inversión Bursátil (S.A.P.I.B.)
               </MenuItem>
               <MenuItem value="sapi" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Sociedad Anónima Promotora de Inversión (S.A.P.I.)
               </MenuItem>
               <MenuItem value="sca" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Sociedad en Comandita por Acciones (S.C.A.)
               </MenuItem>
               <MenuItem value="sc" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Sociedad Civil (S.C.)
               </MenuItem>
               <MenuItem value="scoop" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Sociedad Cooperativa (S.Coop.)
               </MenuItem>
               <MenuItem value="sas" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Sociedad por Acciones Simplificada (S.A.S.)
               </MenuItem>
               <MenuItem value="scs" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Sociedad en Comandita Simple (S. en C.S.)
               </MenuItem>
               <MenuItem value="snc" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Sociedad en Nombre Colectivo (S.N.C.)
               </MenuItem>
               <MenuItem value="ss" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  Sociedades de Solidaridad Social (S. de S.S.)
               </MenuItem>
            </TextField>
         </Grid>
      </>
   );
};

export default StepOneForm;
