import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const StyledAccordion = ({
   question,
   answer,
   selectedAnswer,
   setSelectedAnswer,
   setSelectedVideo,
   videoUrl,
   title,
   mainTitleContent,
}) => {
   const handleClick = () => {
      setSelectedVideo(videoUrl);
      setSelectedAnswer(mainTitleContent || answer);
   };

   const isActive = selectedAnswer === (mainTitleContent || answer);

   return (
      <Button
         className={isActive ? "gradientBox904" : undefined}
         onClick={handleClick}
         onTouchStart={handleClick}
         style={{
            backgroundColor: "#E8ECEF",
            padding: "8px 0",
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            width: "80%",
            textTransform: "none",
         }}
      >
         <Stack spacing={4} direction="row" width="100%" justifyContent="space-between" alignItems="center" px={3}>
            <Typography
               sx={{
                  fontFamily: "ExtraBold",
                  pl: 8, // Ajuste aquí
                  fontSize: isActive ? "20px" : "16px",
                  color: "#162c44",
                  transition: "font-size 0.3s ease",
                  whiteSpace: "pre-line",
                  textAlign:"left"
               }}
            >
               {mainTitleContent ? title : question}
            </Typography>

            {!isActive && (
               <ChevronRightIcon
                  sx={{
                     color: "#162c44",
                  }}
               />
            )}
         </Stack>
      </Button>
   );
};

export const StyledAccordionComponent = ({ content, selectedAnswer, setSelectedAnswer, setSelectedVideo }) => {
   return (
      <Stack direction="column">
         {content.map((e, index) => (
            <Stack key={index} spacing={0} sx={{ marginBottom: 1 }}>
               <StyledAccordion
                  title={e.title}
                  question={e.question}
                  answer={e.answer}
                  videoUrl={e.videoUrl}
                  selectedAnswer={selectedAnswer}
                  setSelectedAnswer={setSelectedAnswer}
                  setSelectedVideo={setSelectedVideo}
                  mainTitleContent={e.isMainTitle ? e.mainTitleContent : null} // Solo pasar el mainTitleContent si es el título principal
               />
               {e.isMainTitle && (
                  <Divider
                     sx={{
                        bgcolor: "#162c44",
                        width: { xs: "40%", lg: "60%" },
                        borderBottomWidth: "3px",
                        marginBottom: 1,
                     }}
                  />
               )}
            </Stack>
         ))}
      </Stack>
   );
};

StyledAccordionComponent.propTypes = {
   content: PropTypes.array.isRequired,
   selectedAnswer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
   setSelectedAnswer: PropTypes.func,
   setSelectedVideo: PropTypes.func,
};

StyledAccordion.propTypes = {
   question: PropTypes.string.isRequired,
   answer: PropTypes.string.isRequired,
   selectedAnswer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
   setSelectedAnswer: PropTypes.func,
   setSelectedVideo: PropTypes.func,
   videoUrl: PropTypes.string,
   title: PropTypes.string,
   mainTitleContent: PropTypes.string,
};
