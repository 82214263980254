import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Button, Stack } from "@mui/material";
import { Footer, NavBar } from "../components";
import { ThemeConfig } from "../config/theme.config";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ScrollToTop from "../wrapper/scrollToTop";
import "./gradientBoxes.css";
import { QuestionAccordion } from "../components/questionAccordion";
import { ScrollToTopButton } from "../components/scrollToTopButton";
import { DenounceQuestions } from "../const/AccordionQuestions";
import VideoContainer from "../components/VideoContainer";
import { descriptionZone, imageZone } from "./ServicesScreen";
import ServiceTitleSection from "../components/ServiceTitleSection";
import { StyledAccordionComponent } from "../components/StyledAccordion/styledAccordionComponent";
import { DenounceChannelContent } from "../const/StyledAccordionContent";
import { DenounceBenefits } from "../const/ServicesBenefits";
import WhatIsServiceComponent from "../components/WhatIsServiceComponent";
import ServiceImportanceSection from "../components/ServiceImportanceSection";
import { useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Slider from "react-slick";

const facilities = (description, bold) => {
  return (
    <Box sx={{ display: "flex", py: 1, alignItems: "center" }}>
      <CheckCircleIcon
        sx={{ bgcolor: "#D9D9D9", color: bold ? "#162c44" : "#8A95A1", mx: 1 }}
      />
      <Typography fontFamily={"bold"} variant="subtitle2">
        {description}
      </Typography>
    </Box>
  );
};

const sliderSettings = {
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 5000,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

export const DenounceScreen = () => {
  const styledAccContent = {
    details: (
      <Typography textAlign={"justify"} key={"mainTitleContent"}>
        A través de nuestra plataforma personalizada con la identidad
        corporativa de su organización, la persona interesada interpondrá su
        denuncia, queja o sugerencia desde cualquier dispositivo y desde
        cualquier lugar.
        <br />
        <br />
        Además, es posible realizar, en tiempo real, el seguimiento de las
        denuncias, quejas o sugerencias, ingresando el número de reporte y el
        código de verificación que le es proporcionado al usuario.
      </Typography>
    ),
    videoUrl:
      "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/videos/1.+Interposici%C3%B3n+de+denuncia%2C+queja+o+sugerencia.mp4",
  };
  const navigate = useNavigate();
  const [selectedText, setSelectedText] = useState(styledAccContent.details);
  const [videoContainerUrl, setVideoContainerUrl] = useState(
    styledAccContent.videoUrl
  );

  const facilitiesList = [
    "Rápida implementación",
    "Fácil acceso y uso",
    "Diseño personalizado",
    "Capacitación personalizada",
    "Informes y seguimiento",
    "Herramientas de mkt digital",
    "Soporte técnico",
    "Sistema en la nube",
  ];
  const facilitiesSecondaryList = [
    "Proceso de gestión",
    "Clasificación",
    "Análisis",
    "Proceso de investigación",
    "Recomendaciones",
    "Resolución",
  ];

  const facilitiesGrid = (intern) => {
    return (
      <Grid container>
        <Grid item xs={5.5}>
          {facilitiesList.map((e) => {
            return facilities(e);
          })}
        </Grid>
        <Grid
          item
          xs={6.5}
          sx={{
            display: "flex",
            height: {
              xs: intern ? "95px" : "260px",
              md: intern ? "80px" : "250px",
            },
          }}
        >
          <Box
            width={"3px"}
            sx={{
              my: 3,
              bgcolor: "#162c44",
              position: "relative",
              left: "21px",
            }}
          />
          <Box zIndex={2}>
            {facilitiesSecondaryList.map((e, i) => {
              if (i === 0) return facilities(e, true);
              if (intern && i > 1) return undefined;
              return facilities(e);
            })}
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <ThemeConfig>
      <ScrollToTop>
        <Stack direction={"column"}>
          <Box position={"relative"} zIndex={1} py={5}>
            <Box position={"absolute"} top={0} width={"100%"} zIndex={-1}>
              <Slider centerMode={false} {...sliderSettings} adaptiveHeight>
                <Box
                  sx={{
                    backgroundImage:
                      "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/fondos/CD-Banner.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: { xs: "450px", lg: "115vh" },
                  }}
                />
                <Box
                  sx={{
                    backgroundImage:
                      "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/fondos/CD+Banner+2.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: { xs: "450px", lg: "115vh" },
                  }}
                />
                <Box
                  sx={{
                    backgroundImage:
                      "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/fondos/CD-Banner-3.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: { xs: "450px", lg: "115vh" },
                  }}
                />
              </Slider>
            </Box>
            <NavBar />
            <ServiceTitleSection
              title={"Canal de denuncias"}
              phrase={"Un símbolo de transparencia y mejora continua"}
              icon={
                "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/iconos/ICONOS+de+m%C3%B3dulos+LECOSY_Canal+de+denuncia.svg)"
              }
            />
          </Box>
          <Box
            my={{ xs: -5, lg: -10 }}
            position={"relative"}
            bgcolor={"transparent"}
            zIndex={2}
            sx={{
              backgroundImage: `url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/curvedBg.png)`,
              backgroundSize: { xs: "100% 125%", lg: "100% 135%" },
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <Box px={{ xs: 3, lg: 10 }}>
              <VideoContainer
                videoUrl={
                  "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/videos/Escaneando+codigo+QR+del+CANAL+DE+DENUNCIAS.mp4"
                }
                direction="row-reverse"
                alternComponent={
                  <Typography
                    sx={{ color: "#162c44", fontSize: { md: "18px" } }}
                    py={{ lg: 10 }}
                  >
                    En el camino hacia la mejora continua, el Canal de Denuncias{" "}
                    <strong>
                      es el primer paso para fomentar una cultura empresarial
                      sólida y responsable.
                    </strong>
                  </Typography>
                }
                videoPlayerProps={{
                  height: "100%",
                  width: { xs: "100%", lg: "75%" },
                  alignSelf: "center",
                }}
              />
            </Box>
          </Box>
          <WhatIsServiceComponent
            what="Un canal de denuncias es una herramienta para que los empleados, clientes o cualquier otra persona pueda informar de manera confidencial y anónima sobre cualquier actividad ilegal, inapropiada o no ética que ocurra dentro de la organización."
            why="A través de esta herramienta es posible detectar y prevenir actividades indebidas antes de que se
      conviertan en problemas mayores que puedan afectar la integridad de las organizaciones. Además, su
      implementación es una herramienta efectiva para fomentar una cultura de transparencia y honestidad
      dentro de una organización."
            imageUrl={
              "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/canal-denuncias/fondos/8+1.png)"
            }
            fullHeight
          />
          <VideoContainer
            direction="row-reverse"
            title={
              <Typography
                fontFamily={"bold"}
                ml={{ lg: "10%" }}
                fontSize={{ xs: "30px", lg: "35px" }}
                lineHeight={1.2}
              >
                ¿Cómo funciona el{" "}
                <Typography
                  fontFamily={"ExtraBold"}
                  fontSize={{ xs: "30px", lg: "35px" }}
                >
                  Módulo de Canal de Denuncias?
                </Typography>
              </Typography>
            }
            alternComponent={
              <StyledAccordionComponent
                title="Interposición de denuncia, queja o sugerencia"
                content={DenounceChannelContent}
                selectedAnswer={selectedText}
                setSelectedAnswer={setSelectedText}
                setSelectedVideo={setVideoContainerUrl}
                mainTitleContent={styledAccContent.details}
                mainVideoContent={styledAccContent.videoUrl}
              />
            }
            videoUrl={videoContainerUrl}
            videoPlayerProps={{ width: { lg: "80%" }, mx: "auto" }}
            subComponent={
              <Stack direction={"column"} spacing={2} px={{ xs: 3, lg: 10 }}>
                <Typography>{selectedText}</Typography>
              </Stack>
            }
          />
          <ServiceImportanceSection
            title="¿Debo tener un canal de denuncias en mi organización?"
            description={
              <Stack direction={"column"} spacing={2} flex={1}>
                <Typography textAlign={"justify"}>
                  La legislación mexicana establece que las empresas pueden ser
                  penalmente responsables de los delitos cometidos en su nombre
                  si no cuentan con el debido control en su organización. La Ley
                  General de Responsabilidades Administrativas establece que se
                  valorará si la empresa cuenta con políticas de integridad y
                  sistemas adecuados de denuncia. Además, contar con
                  herramientas de denuncia puede ser considerado como una
                  atenuante en la imposición de sanciones a la empresa.
                </Typography>
                <Typography textAlign={"justify"}>
                  Por lo tanto, tener una herramienta de denuncia en la
                  organización puede protegerla legal y reputacionalmente,
                  además de ser una buena práctica y un requisito en algunas
                  normas oficiales mexicanas (NOM-035-STPS factores de riesgo
                  psicosocial en el trabajo, NOM-247-SE prácticas
                  comerciales...).
                </Typography>
              </Stack>
            }
            phrase={
              <Typography
                fontSize={{ xs: "20px", md: "25px" }}
                textAlign={"center"}
                pt={{ xs: 2, lg: 8 }}
                px={{ lg: 30 }}
                lineHeight={1.2}
              >
                Ofrecemos a las empresas la posibilidad de{" "}
                <strong>prevenir, mitigar o erradicar riesgos</strong> mediante
                una&nbsp;{""}
                <strong>herramienta de gestión</strong> que proporcione los
                elementos necesarios para ello.
              </Typography>
            }
            additionalComponent={
              <Box
                display={"flex"}
                justifyContent={"center"}
                py={{ xs: 2, lg: 5 }}
              >
                <Button
                  sx={{ bgcolor: "white", "&:hover": { bgcolor: "#8A95A1" } }}
                  onClick={() => {
                    const element = document.getElementById("plans");
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "center",
                    });
                  }}
                >
                  <Typography color={"#162c44"} fontFamily={"bold"}>
                    Quiero un canal de denuncias para mi empresa
                  </Typography>
                </Button>
              </Box>
            }
          />
          <Stack
            direction={"column"}
            spacing={3}
            py={10}
            sx={{
              backgroundImage:
                "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Fondo+beneficios.png)",
              backgroundPosition: "center top",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              px={{ xs: 3, lg: "auto" }}
            >
              <Typography
                textAlign={"center"}
                color={"#162c44"}
                fontFamily={"bold"}
                fontSize={{ xs: "30px", lg: "35px" }}
                sx={{ borderBottom: 5 }}
              >
                Beneficios que logrará con la <br />
                implementación del canal de denuncias
              </Typography>
            </Box>
            {DenounceBenefits.map((benefit, i) => {
              return (
                <Box
                  id={`section${i + 1}`}
                  key={benefit.title}
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      md: i % 2 === 0 ? "row" : "row-reverse",
                    },
                    alignItems: { md: "center" },
                    py: 1,
                  }}
                >
                  {descriptionZone(benefit, i)}
                  {imageZone(benefit, i % 2 !== 0)}
                </Box>
              );
            })}
          </Stack>
          <Box
            className="gradientBox902"
            sx={{ px: { xs: 2, md: 15 }, py: { xs: 3, md: 5 } }}
            id={"plans"}
          >
            <AnimationOnScroll
              animateIn="animate__zoomIn"
              duration={0.75}
              animateOnce
              animatePreScroll={false}
            >
              <Typography
                color={"white"}
                textAlign={"center"}
                sx={{
                  pt: { xs: 3, md: 8 },
                  fontSize: { xs: "30px", md: "40px" },
                }}
              >
                <strong>Elija el plan</strong> que mejor{" "}
                <strong>se ajuste a sus necesidades.</strong>
              </Typography>
            </AnimationOnScroll>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", lg: "space-evenly" },
                flexDirection: { xs: "column", md: "row" },
                py: 9,
              }}
            >
              <AnimationOnScroll
                animateIn="animate__slideInUp"
                duration={0.75}
                animatePreScroll
                animateOnce
              >
                <Box display={"grid"} sx={{ justifyContent: "center" }}>
                  <Typography
                    fontFamily={"bold"}
                    textAlign={"center"}
                    color={"primary"}
                    variant="h5"
                    gridRow={"4/6"}
                    gridColumn={"2/3"}
                    zIndex={2}
                    sx={{ borderRadius: 2, bgcolor: "white", p: "4px 45px" }}
                  >
                    1
                  </Typography>
                  <Box
                    gridRow={"5/10"}
                    gridColumn={"1/4"}
                    sx={{
                      bgcolor: "#D9D9D9",
                      p: { md: "40px 15px" },
                      borderRadius: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      maxWidth: "500px",
                      minHeight: "680px",
                      maxHeight: { xs: "860px", md: "700px" },
                    }}
                  >
                    <Typography
                      textAlign={"center"}
                      color={"#5C6C7C"}
                      fontFamily={"bold"}
                      sx={{ fontSize: { xs: "30px", md: "40px" }, mt: "10%" }}
                    >
                      Gestión interna
                    </Typography>
                    <Button
                      onClick={() => navigate("/contacto")}
                      sx={{
                        borderRadius: 2,
                        px: 2,
                        alignSelf: "center",
                        my: 2,
                        mt: 4,
                        bgcolor: "#5C6C7C",
                      }}
                    >
                      INVIERTA YA
                    </Button>
                    <Box sx={{ minHeight: "130px" }}>
                      <Typography textAlign={"center"} sx={{ py: 1 }}>
                        Dirigida a organizaciones que cuenten con la estructura
                        necesaria para autogestionar sus denuncias, quejas o
                        sugerencias.
                      </Typography>
                      <br />
                    </Box>
                    {facilitiesGrid(true)}
                  </Box>
                </Box>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="animate__slideInUp"
                duration={0.75}
                delay={1200}
                animatePreScroll
                animateOnce
              >
                <Box
                  display={"grid"}
                  sx={{ justifyContent: "center", pt: { xs: 5, md: 0 } }}
                >
                  <Typography
                    fontFamily={"bold"}
                    textAlign={"center"}
                    color={"primary"}
                    variant="h5"
                    gridRow={"4/6"}
                    gridColumn={"2/3"}
                    zIndex={2}
                    sx={{ borderRadius: 2, bgcolor: "white", p: "4px 45px" }}
                  >
                    2
                  </Typography>
                  <Box
                    gridRow={"5/10"}
                    gridColumn={"1/4"}
                    sx={{
                      bgcolor: "#D9D9D9",
                      p: { md: "40px 15px" },
                      borderRadius: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      maxWidth: "500px",
                      minHeight: "680px",
                      maxHeight: { xs: "860px", md: "700px" },
                    }}
                  >
                    <Typography
                      textAlign={"center"}
                      color={"#162c44"}
                      fontFamily={"bold"}
                      sx={{ fontSize: { xs: "30px", md: "40px" }, mt: "10%" }}
                    >
                      Gestión Lecosy
                    </Typography>
                    <Button
                      onClick={() => navigate("/contacto")}
                      sx={{
                        borderRadius: 2,
                        px: 2,
                        alignSelf: "center",
                        my: 2,
                        mt: 4,
                        bgcolor: "#162c44",
                        "&:hover": { bgcolor: "#5C6C7C" },
                      }}
                    >
                      INVIERTA YA
                    </Button>
                    <Box sx={{ minHeight: "130px" }}>
                      <Typography textAlign={"center"} sx={{ py: 1 }}>
                        Ideal para organizaciones de cualquier tipo y tamaño que
                        necesitan un equipo especializado que se encargue de
                        gestionar sus denuncias, quejas o sugerencias de manera
                        profesional y eficiente.
                      </Typography>
                      <br />
                    </Box>
                    {facilitiesGrid(false)}
                  </Box>
                </Box>
              </AnimationOnScroll>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundImage:
                "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Figures/Figura-5.png)",
              backgroundSize: { xs: "0% 0%", md: "55% 100%" },
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom right",
            }}
          >
            <Box
              height={{ lg: "600px" }}
              width={"400px"}
              position={"absolute"}
              right={0}
              my={"2%"}
              sx={{
                backgroundImage:
                  "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Capa_1.png)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
            <Typography
              fontFamily={"bold"}
              color={"primary"}
              sx={{
                px: { xs: 2, md: 10 },
                pt: 5,
                fontSize: { xs: "30px", md: "40px" },
              }}
            >
              Preguntas frecuentes
            </Typography>
            {QuestionAccordion(DenounceQuestions, true)}
          </Box>
          {ScrollToTopButton(window)}
          <Footer />
        </Stack>
      </ScrollToTop>
    </ThemeConfig>
  );
};
