import { Box, Stack } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const ServiceJustifySection = ({ title, phrase, imageUrl }) => {
   return (
      <Stack direction={{ xs: "column-reverse", lg: "row" }} py={5}>
         {title}
         <Stack direction={"column"} flex={1} px={{ xs: 3, lg: 10 }} alignItems={"center"}>
            <Box
               width={{ xs: "100%", lg: "70%" }}
               height={"250px"}
               sx={{
                  backgroundImage: `url(${imageUrl})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
               }}
            />
            {phrase}
         </Stack>
      </Stack>
   );
};

ServiceJustifySection.propTypes = {
   title: React.Component.isRequired,
   phrase: React.Component.isRequired,
   imageUrl: PropTypes.string.isRequired,
};

export default ServiceJustifySection;
