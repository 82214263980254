import React from "react";
import { Grid, TextField, Typography, IconButton, MenuItem } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";

const StepTwoForm = ({ formData, handleChange }) => {
   const [retos, setRetos] = useState(formData.find((item) => item.field === "principalesRetos")?.value || []);
   const [currentReto, setCurrentReto] = useState('');
   const maxRetos = 5;
   const maxCharacters = 350;

   const handleKeyDown = (e) => {
      if (e.key === 'Enter' && currentReto.trim() !== '') {
         if (retos.length < maxRetos) {
            const newRetos = [...retos, currentReto.trim()];
            setRetos(newRetos);
            setCurrentReto(''); // Limpiar el campo de entrada
            // Actualiza el formData con los nuevos retos
            handleChange({ target: { name: "principalesRetos", value: newRetos } });
         } else {
            alert('Solo puedes ingresar hasta 5 retos');
         }
         e.preventDefault(); // Evitar que el formulario se envíe al presionar Enter
      }
   };

   const handleChange2 = (e) => {
      if (e.target.value.length <= maxCharacters) {
         setCurrentReto(e.target.value);
      }
   };

   const handleDeleteReto = (index) => {
      const updatedRetos = retos.filter((_, i) => i !== index);
      setRetos(updatedRetos);
      // Actualiza el formData con los retos actualizados
      handleChange({ target: { name: "principalesRetos", value: updatedRetos } });
   };

   return (
      <>
         {/* Preguntas del Paso 2 */}
         <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               ¿En su empresa se toman las decisiones de manera colegiada tomando en cuenta a todas las partes
               interesadas?
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="decisionesColegiadas"
               value={formData.find((item) => item.field === "decisionesColegiadas")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="1" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">1- No, las decisiones dependen de una o dos personas.</Typography>
               </MenuItem>
               <MenuItem value="2" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">2 - Rara vez tomamos decisiones en conjunto.</Typography>
               </MenuItem>
               <MenuItem value="3" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     3 - Las decisiones más importantes se toman de manera colegiada
                  </Typography>
               </MenuItem>
               <MenuItem value="4" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     4 - Sí, tenemos reuniones de análisis de información y toma de decisiones.
                  </Typography>
               </MenuItem>
               <MenuItem value="5" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     5 - Sí, tenemos todos los procesos de toma de decisiones formalmente definidos.
                  </Typography>
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               ¿Tiene adecuadamente definidos los riesgos a los que se encuentra expuesta la empresa?
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="riesgosDefinidos"
               value={formData.find((item) => item.field === "riesgosDefinidos")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="1" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     1 - No, nuestra empresa no tiene adecuadamente definidos los riesgos. Actualmente, no hemos
                     implementado un sistema formal de identificación y evaluación de riesgos.
                  </Typography>
               </MenuItem>
               <MenuItem value="2" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     2 - Hemos comenzado a identificar algunos riesgos, pero la definición aún es parcial y no abarca
                     todas las áreas críticas de la empresa.
                  </Typography>
               </MenuItem>
               <MenuItem value="3" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     3 - Sí, contamos con una definición básica de los principales riesgos a los que se enfrenta la
                     empresa, y hemos implementado algunas medidas de mitigación.
                  </Typography>
               </MenuItem>
               <MenuItem value="4" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     4 - Sí, nuestra empresa tiene adecuadamente definidos los riesgos, y hemos implementado un sistema
                     formal para gestionarlos.
                  </Typography>
               </MenuItem>
               <MenuItem value="5" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     5 - Sí, tenemos perfectamente definidos todos los riesgos a los que está expuesta la empresa.
                     Contamos con un sistema de gestión de riesgos integral y robusto, que es revisado y actualizado
                     regularmente.
                  </Typography>
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               ¿La empresa busca atraer inversiones o financiamiento externo?
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="inversionesExternas"
               value={formData.find((item) => item.field === "inversionesExternas")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="1" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     1 - No, la empresa no tiene un plan claro para atraer inversiones o financiamiento externo.
                  </Typography>
               </MenuItem>
               <MenuItem value="2" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     2 - La empresa ha considerado la posibilidad de atraer inversiones o financiamiento externo, pero
                     aún no ha formalizado un plan detallado.
                  </Typography>
               </MenuItem>
               <MenuItem value="3" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     3 - La empresa ha iniciado conversaciones preliminares con posibles inversores o entidades
                     financieras, pero aún no ha asegurado compromisos firmes.
                  </Typography>
               </MenuItem>
               <MenuItem value="4" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     4 - La empresa está en proceso de negociación activa con inversores y ha asegurado algunos
                     compromisos preliminares.
                  </Typography>
               </MenuItem>
               <MenuItem value="5" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     5 - Sí, la empresa ha asegurado inversiones o financiamiento externo de manera efectiva y tiene una
                     estrategia consolidada para futuras necesidades financieras.
                  </Typography>
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               ¿La empresa enfrenta riesgos operacionales significativos que no están siendo gestionados adecuadamente?
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="riesgosOperacionales"
               value={formData.find((item) => item.field === "riesgosOperacionales")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="1" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     1 - Sí, la empresa enfrenta riesgos operacionales significativos que no están siendo gestionados
                     adecuadamente.
                  </Typography>
               </MenuItem>
               <MenuItem value="2" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     2 - La empresa ha identificado algunos riesgos operacionales, pero la gestión es inconsistente y
                     carece de un enfoque sistemático.
                  </Typography>
               </MenuItem>
               <MenuItem value="3" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     3 - Los riesgos operacionales han sido identificados y se están implementando algunas medidas de
                     mitigación, pero aún hay áreas que requieren mejoras.
                  </Typography>
               </MenuItem>
               <MenuItem value="4" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     4 - La empresa tiene un sistema formal de gestión de riesgos operacionales en funcionamiento, pero
                     se está trabajando en mejorarlo continuamente.
                  </Typography>
               </MenuItem>
               <MenuItem value="5" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     5 - No, la empresa gestiona eficazmente los riesgos operacionales y tiene un sistema robusto y bien
                     establecido.
                  </Typography>
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               ¿Hay problemas en la gestión interna, como falta de claridad en roles y responsabilidades, decisiones
               desacertadas o inconsistentes?
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="problemasGestionInterna"
               value={formData.find((item) => item.field === "problemasGestionInterna")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="1" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     1 - Sí, hay problemas significativos en la gestión interna, como falta de claridad en roles y
                     responsabilidades, y decisiones desacertadas frecuentes.
                  </Typography>
               </MenuItem>
               <MenuItem value="2" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     2 - Existen algunos problemas identificados en la gestión interna, como decisiones inconsistentes y
                     falta de alineación en roles clave.
                  </Typography>
               </MenuItem>
               <MenuItem value="3" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     3 - Se han identificado algunos problemas ocasionales en la gestión interna, pero se están tomando
                     medidas para abordarlos de manera proactiva.
                  </Typography>
               </MenuItem>
               <MenuItem value="4" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     4 - La empresa tiene un sistema formal para la gestión interna, aunque ocasionalmente surgen
                     desafíos que se abordan de manera efectiva.
                  </Typography>
               </MenuItem>
               <MenuItem value="5" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     5 - No, la empresa gestiona de manera efectiva la gestión interna, con roles y responsabilidades
                     claros y decisiones consistentes y bien fundamentadas.
                  </Typography>
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               ¿La competencia en el mercado es intensa, y la empresa necesita un mecanismo eficiente para responder
               rápidamente y de manera efectiva a los cambios del mercado?
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="competenciaMercado"
               value={formData.find((item) => item.field === "competenciaMercado")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="1" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     1 - Sí, la competencia en el mercado es intensa y la empresa tiene dificultades para responder
                     rápidamente a los cambios.
                  </Typography>
               </MenuItem>
               <MenuItem value="2" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     2 - La empresa reconoce la intensa competencia y está trabajando en mejorar su capacidad de
                     respuesta, pero aún enfrenta desafíos significativos.
                  </Typography>
               </MenuItem>
               <MenuItem value="3" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     3 - La empresa tiene mecanismos establecidos para responder a los cambios del mercado, pero se
                     necesita mejorar la velocidad y efectividad de estas respuestas.
                  </Typography>
               </MenuItem>
               <MenuItem value="4" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     4 - La empresa responde eficazmente a los cambios del mercado, pero busca optimizar sus estrategias
                     de respuesta.
                  </Typography>
               </MenuItem>
               <MenuItem value="5" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     5 - Sí, la empresa gestiona de manera efectiva la competencia en el mercado y tiene sistemas
                     robustos para adaptarse ágilmente a los cambios.
                  </Typography>
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               ¿Hay un plan claro de sucesión para los altos cargos?
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="planSucesion"
               value={formData.find((item) => item.field === "planSucesion")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="1" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">1 - No hay un plan claro de sucesión para los altos cargos.</Typography>
               </MenuItem>
               <MenuItem value="2" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     2 - Existe un plan de sucesión incipiente, pero carece de formalización y claridad en la
                     implementación.
                  </Typography>
               </MenuItem>
               <MenuItem value="3" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     3 - Hay un plan de sucesión establecido, aunque necesita mejoras en términos de desarrollo y
                     seguimiento.
                  </Typography>
               </MenuItem>
               <MenuItem value="4" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     4 - La empresa tiene un plan de sucesión bien estructurado y en funcionamiento, aunque se puede
                     mejorar la preparación y el desarrollo de sucesores potenciales.
                  </Typography>
               </MenuItem>
               <MenuItem value="5" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     5 - Sí, la empresa gestiona efectivamente un plan de sucesión integral y bien implementado para los
                     altos cargos.
                  </Typography>
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               ¿Existen problemas significativos en la comunicación interna, lo que resulta en malentendidos, duplicidad
               de esfuerzos y falta de alineación en los objetivos estratégicos?
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="problemasComunicacion"
               value={formData.find((item) => item.field === "problemasComunicacion")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="1" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     1 - Sí, existen problemas significativos en la comunicación interna, resultando en malentendidos y
                     falta de alineación en los objetivos estratégicos.
                  </Typography>
               </MenuItem>
               <MenuItem value="2" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     2 - Los problemas de comunicación interna son reconocidos, pero aún persisten malentendidos y
                     cierta falta de alineación en los objetivos estratégicos.
                  </Typography>
               </MenuItem>
               <MenuItem value="3" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     3 - Hay esfuerzos para mejorar la comunicación interna, aunque persisten algunos malentendidos y
                     falta de alineación en objetivos estratégicos.
                  </Typography>
               </MenuItem>
               <MenuItem value="4" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     4 - La empresa está gestionando activamente la comunicación interna, aunque se requiere una
                     optimización continua para alinear completamente los objetivos estratégicos.
                  </Typography>
               </MenuItem>
               <MenuItem value="5" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     5 - Sí, la empresa gestiona efectivamente la comunicación interna y tiene sistemas robustos para
                     alinear los objetivos estratégicos.
                  </Typography>
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               ¿La empresa ha adoptado tecnologías modernas que podrían mejorar la eficiencia operativa, la toma de
               decisiones y la gestión de información?
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="tecnologiasModernas"
               value={formData.find((item) => item.field === "tecnologiasModernas")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="1" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     1 - No, la empresa no ha adoptado tecnologías modernas para mejorar la eficiencia operativa, la
                     toma de decisiones ni la gestión de información.
                  </Typography>
               </MenuItem>
               <MenuItem value="2" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     2 - La empresa ha considerado la adopción de algunas tecnologías modernas, pero aún no se han
                     implementado de manera significativa.
                  </Typography>
               </MenuItem>
               <MenuItem value="3" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     3 - La empresa ha implementado algunas tecnologías modernas, pero la integración y el uso aún
                     pueden mejorar para optimizar la eficiencia operativa y la toma de decisiones.
                  </Typography>
               </MenuItem>
               <MenuItem value="4" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     4 - La empresa ha adoptado varias tecnologías modernas y está en camino de mejorar la eficiencia
                     operativa y la toma de decisiones.
                  </Typography>
               </MenuItem>
               <MenuItem value="5" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     5 - Sí, la empresa ha adoptado tecnologías modernas de manera integral, mejorando
                     significativamente la eficiencia operativa, la toma de decisiones y la gestión de información.
                  </Typography>
               </MenuItem>
            </TextField>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               ¿Se han identificado problemas en el control financiero, como errores en la contabilidad, fraudes o falta
               de previsión presupuestaria?
            </Typography>
            <TextField
               fullWidth
               select
               label="Seleccione"
               name="problemasControlFinanciero"
               value={formData.find((item) => item.field === "problemasControlFinanciero")?.value}
               onChange={handleChange}
               variant="filled"
            >
               <MenuItem value="1" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     1 - Sí, se han identificado problemas significativos en el control financiero, como errores
                     frecuentes en la contabilidad, algunos incidentes y falta de previsión presupuestaria.
                  </Typography>
               </MenuItem>
               <MenuItem value="2" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     2 - Han surgido problemas en el control financiero, con algunos errores ocasionales en la
                     contabilidad, incidentes esporádicos de fraude y desafíos intermitentes en la previsión
                     presupuestaria.
                  </Typography>
               </MenuItem>
               <MenuItem value="3" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     3 - Se han identificado algunos problemas en el control financiero, con errores esporádicos en la
                     contabilidad, acciones puntuales de fraude y ciertas deficiencias en la previsión presupuestaria.
                  </Typography>
               </MenuItem>
               <MenuItem value="4" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     4 - Los problemas en el control financiero han sido identificados y se están implementando medidas
                     correctivas, incluyendo errores ocasionales en la contabilidad, controles mejorados contra fraudes
                     y una previsión presupuestaria más precisa.
                  </Typography>
               </MenuItem>
               <MenuItem value="5" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" }, whiteSpace: "normal" }}>
                  <Typography variant="body2">
                     5 - No se han identificado problemas significativos en el control financiero. La contabilidad es
                     precisa, no se han detectado incidentes de fraude y la previsión presupuestaria se gestiona de
                     manera eficiente.
                  </Typography>
               </MenuItem>
            </TextField>
         </Grid>
         <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
               Hágannos saber los principales retos que enfrenta su organización (máximo {maxRetos} retos)
            </Typography>
            <TextField
               fullWidth
               label="Escriba su reto y presione Enter"
               name="currentReto"
               value={currentReto}
               onChange={handleChange2}
               onKeyDown={handleKeyDown}
               variant="filled"
               inputProps={{ maxLength: maxCharacters }} // Limitar caracteres por reto
            />
            <Typography variant="caption">
               {currentReto.length}/{maxCharacters} caracteres
            </Typography>
            <ol>
               {retos.map((reto, index) => (
                  <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
                     <Typography variant="body1">{reto}</Typography>
                     <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteReto(index)}
                        size="small"
                        sx={{ ml: 2 }}
                     >
                        <DeleteIcon fontSize="small" />
                     </IconButton>
                  </li>
               ))}
            </ol>
         </Grid>
      </>
   );
};

export default StepTwoForm;
