import { TextField } from "@mui/material";
import { useField } from "formik";
import PropTypes from "prop-types";

export const InputTextField = ({ id, name, label, multiline, rows }) => {
   const [field, meta] = useField(name);
   return (
      <TextField
         id={id}
         name={name}
         size={"small"}
         fullWidth
         label={label}
         multiline={multiline}
         rows={rows}
         variant={"outlined"}
         {...field}
         error={meta.error && meta.touched}
         helperText={meta.error && meta.touched ? meta.error : null}
      />
   );
};

InputTextField.propTypes = {
   id: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   label: PropTypes.string.isRequired,
   multiline: PropTypes.bool,
   rows: PropTypes.number,
};
