import React from "react";
import {
  Box,
  Typography,
  Modal,
  Button,
  IconButton,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

/**
 * Custom theme configuration for the modal and other MUI components.
 */
const theme = createTheme({
  palette: {
    primary: {
      main: "#162C44",
    },
    background: {
      default: "#E8ECEF",
    },
  },
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          border: "none !important",
          outline: "none !important",
          boxShadow: "none",
          "&:focus, &:active, &:hover, &:focus-visible": {
            border: "none !important",
            outline: "none !important",
          },
          "&::before, &::after": {
            border: "none !important",
            outline: "none !important",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          borderRadius: "12px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.4)",
          "& .MuiFilledInput-root": {
            borderRadius: "12px",
            boxShadow: "none",
            "&:before, &:after, &:hover:not(.Mui-disabled):before, &.Mui-focused:after": {
              borderBottom: "none !important",
            },
          },
          "& .MuiInputBase-input": {
            color: "#162C44",
          },
          "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
            color: "#162C44",
          },
          "& .MuiInputBase-input::placeholder, & .Mui-focused .MuiInputBase-input::placeholder": {
            color: "#162C44",
            opacity: 1,
          },
        },
      },
    },
  },
});

/**
 * GovernanceTestModal component to display a modal with a custom theme, 
 * including a close button, title, description, and a call-to-action button.
 * 
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Boolean value to control the modal's open state.
 * @param {function} props.handleClose - Function to close the modal.
 * @returns {JSX.Element} The rendered modal component.
 */
export const GovernanceTestModal = ({ open, handleClose }) => {
  const navigate = useNavigate();

  /**
   * Handles the navigation to the landing page when the button is clicked.
   */
  const handleDiagnosticoClick = () => {
    navigate("/landing-page");
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(3px)",
            border:'none', 
            outline:'none'
          },
        }}
      >
        <Box
          sx={{
            width: { xs: "90%", sm: 600, md: 800 },
            height: { xs: "auto", md: 420 },
            bgcolor: "background.paper",
            border:'none', outline:'none',
            paddingX: { xs: 5, md: 10 },
            paddingY: { xs: 5, md: 10 },
            backgroundImage: "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/modal-bg.svg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: 2,
            color: "#2D4357",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-title"
            variant="h4"
            component="h2"
            sx={{ mb: 2, textAlign: "left" }}
          >
            <strong>Transforme su empresa </strong> con un gobierno corporativo
            eficaz.
          </Typography>
          <Typography
            id="modal-description"
            variant="h6"
            sx={{ mb: 5, textAlign: "left" }}
          >
            Realice nuestro <strong>diagnóstico de gobernanza</strong> y obtenga
            una{" "}
            <strong style={{ color: "#E8606D" }}>
              sesión de consultoría gratuita
            </strong>{" "}
            para fortalecer su gobierno corporativo.
          </Typography>
          <Button
            variant="contained"
            onClick={handleDiagnosticoClick}
            sx={{
              backgroundColor: "#162C44",
              height: "fit-content",
              alignSelf: "flex-start",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Acceder a diagnóstico
            </Typography>
          </Button>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default GovernanceTestModal;
