import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const navbartheme = createTheme({
   palette: {
      mode: "light",
      primary: {
         main: "#162c44",
      },
      secondary: {
         main: "#46566a",
      },
   },
   components: {
      MuiButton: {
         styleOverrides: {
            // Name of the slot
            root: {
               "&:hover": {
                  color: "#8a95a0",
               },
            },
         },
      },
   },
});

export const NavBarThemeConfig = ({ children }) => {
   return (
      <ThemeProvider theme={navbartheme}>
         <CssBaseline />
         {children}
      </ThemeProvider>
   );
};
