import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

const WhatIsServiceComponent = ({ what, why, objective, hasObjective = false, imageUrl, fullHeight = false }) => {
   return (
      <Stack
         direction={{ xs: "column-reverse", lg: hasObjective ? "row-reverse" : "row" }}
         sx={{ bgcolor: "#748190", display: "flex", minHeight: "600px" }}
      >
         <Stack direction={"column"} flex={1}>
            {hasObjective ? undefined : (
               <Box
                  width={{ xs: "100%", lg: "20%" }}
                  height={{ xs: "600px", md: "400px", lg: "600px" }}
                  position={"absolute"}
                  right={0}
                  sx={{
                     backgroundImage:
                        "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/fondos/Fondo-Seccion-3-derecho.png)",
                     backgroundRepeat: "no-repeat",
                     backgroundSize: { xs: "300px 300px", lg: "350px 350px" },
                     backgroundPosition: "bottom right",
                     opacity: 0.15,
                  }}
               />
            )}

            {hasObjective ? (
               <Box
                  flex={1}
                  pt={{ xs: 10, md: 20 }}

                  pb={10}
                  px={{ xs: 3, lg: 20 }}
                  bgcolor={"#46566A"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
               >
                  <Typography
                     fontFamily={"bold"}
                     color={"white"}
                     fontSize={"40px"}
                     lineHeight={1} // Ajusta el interlineado aquí
                     sx={{ mb: 2 }}
                  >
                     ¿Cuáles son las obligaciones a las que se encuentran sujetas las empresas?
                  </Typography>
                  <Typography color={"white"} textAlign={"justify"}>
                     {objective}
                  </Typography>
               </Box>
            ) : undefined}
         </Stack>
         <Box flex={1}>
            <Box
               height={"100%"}
               px={{ xs: 3, lg: 0 }}
               display={"flex"}
               flexDirection={"column"}
               justifyContent={"center"}
            >
               {!hasObjective ? undefined : (
                  <Box
                     width={{ xs: "100%", lg: "20%" }}
                     height={{ xs: "600px", md: "400px", lg: "675px" }}
                     position={"absolute"}
                     right={{ xs: "0", lg: "50%" }}
                     sx={{
                        backgroundImage:
                           "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/gobierno-corporativo/fondos/Fondo-Seccion-3-derecho.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: { xs: "300px 300px", lg: "350px 350px" },
                        backgroundPosition: "bottom right",
                        opacity: 0.15,
                     }}
                  />
               )}
               <Box
                  my={{ xs: 2, lg: "auto" }}
                  sx={{
                     width: "100%",
                     height: fullHeight ? "100%" : { xs: "350px", lg: "400px" },
                     backgroundImage: imageUrl,
                     backgroundSize: "cover",
                     backgroundRepeat: "no-repeat",
                     backgroundPosition: "top center",
                  }}
               />

               <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} px={"10%"} py={"5%"}>
                  <Typography
                     fontFamily={"bold"}
                     color={"white"}
                     sx={{ fontSize: { xs: "30px", md: "40px" }, textAlign: { xs: "center", lg: "left" } }}
                  >
                     ¿Qué es?
                  </Typography>
                  <Typography color={"white"} textAlign={"justify"}>
                     {what}
                  </Typography>
                  {!hasObjective ? (
                     <div>
                        <Typography
                           fontFamily={"bold"}
                           color={"white"}
                           sx={{
                              py: 2,
                              mt: 2,
                              fontSize: { xs: "30px", md: "40px" },
                              textAlign: { xs: "center", md: "left" },
                           }}
                        >
                           ¿Por qué contar con él?
                        </Typography>
                        <Typography color={"white"} textAlign={"justify"}>
                           {why}
                        </Typography>
                     </div>
                  ) : undefined}
               </Box>
            </Box>
         </Box>
      </Stack>
   );
};

WhatIsServiceComponent.propTypes = {
   what: PropTypes.string.isRequired,
   why: PropTypes.string,
   objective: PropTypes.string,
   hasObjective: PropTypes.bool,
   imageUrl: PropTypes.string,
   fullHeight: PropTypes.bool,
};

export default WhatIsServiceComponent;
