import { useState } from "react";
import { Typography, Box, Grid, Divider, Collapse, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { WriteUs } from "./WriteUs";

export const QuestionAccordion = (questions, canSendQuestion = false) => {
   const [expanded, setExpanded] = useState("");
   const [questionsOpen, setQuestionsOpen] = useState(false);

   const handleChange = (panelName) => {
      if (expanded === panelName) return setExpanded("");
      return setExpanded(panelName);
   };

   const accordion = (questionComponent) => {
      return (
         <Box>
            <Grid item xs={12} sx={{ px: 1 }}>
               <Box
                  size="small"
                  onClick={() => handleChange(questionComponent.title)}
                  sx={{
                     "&:hover": { cursor: "pointer" },
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-between",
                  }}
               >
                  <Typography fontFamily={"bold"} color={"primary"} sx={{ ml: 1, py: 1 }}>
                     {questionComponent.question}
                  </Typography>
                  {expanded !== questionComponent.title ? <AddIcon /> : <RemoveIcon />}
               </Box>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "justify", px: 2 }}>
               <Collapse in={expanded === questionComponent.title} sx={{ py: 1 }}>
                  <Typography color={"primary"} textAlign={"justify"} sx={{ pr: { xs: 0, md: 10 } }}>
                     {questionComponent.answer}
                  </Typography>
                  <Typography color={"primary"} textAlign={"justify"} sx={{ pr: { xs: 0, md: 10 } }}>
                     <ul>
                        {questionComponent.list.map((e) => {
                           return <li key={e}>{e}</li>;
                        })}
                     </ul>
                  </Typography>
               </Collapse>
            </Grid>
            <Divider color={"#162c44"} />
         </Box>
      );
   };

   return (
      <Box>
         <Grid container sx={{ px: { xs: 2, md: 10 }, py: 5 }}>
            <Grid item xs={12} md={6}>
               {questions.map((e) => {
                  return accordion(e);
               })}
            </Grid>
         </Grid>
         {canSendQuestion ? (
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: { xs: "center", md: "left" },
                  px: { xs: 3, md: 12 },
                  py: 3,
                  maxWidth: { xs: "100%", md: "700px" },
               }}
            >
               <Button
                  onClick={() => setQuestionsOpen(!questionsOpen)}
                  sx={{ fontSize: "19px", px: 2, mb: { xs: 3, md: 5 }, "&:hover": { bgcolor: "#5c6c7c" } }}
               >
                  Desea hacer una pregunta
               </Button>
               <Collapse in={questionsOpen}>
                  <WriteUs isDivided={true} isQuestion={true} />
               </Collapse>
            </Box>
         ) : undefined}
      </Box>
   );
};
