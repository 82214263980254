import { useState } from "react";
import { NavBar, Footer } from "../components/index.js";
import { Box, Typography, Button, TextField, Checkbox, FormControlLabel, Link } from "@mui/material";
import { ThemeConfig } from "../config/theme.config.js";
import ScrollToTop from "../wrapper/scrollToTop.jsx";
import { useNavigate } from "react-router-dom";
import GridExample from "../components/LandingPage/GridExample.js";
import { useEmail } from "../components/LandingPage/EmailContext.jsx";

/**
 * LandingPageScreen Component
 *
 * Displays the landing page with an introductory section, email input,
 * and navigation to the form page upon acceptance of privacy policy.
 *
 * @component
 * @returns {JSX.Element} The landing page screen.
 */
export const LandingPageScreen = () => {
   const [accepted, setAccepted] = useState(false);
   const { email, setEmail } = useEmail();
   const navigate = useNavigate();

   // Function to validate the email format
   const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
   };

   const handleNavigate = () => {
      if (!accepted) {
         alert("Por favor, acepte el aviso de privacidad.");
         return;
      }

      if (!email || !validateEmail(email)) {
         alert("Por favor, escriba un correo electrónico válido.");
         return;
      }

      navigate("/landing-page/form");
   };

   return (
      <ThemeConfig>
         <ScrollToTop>
            <Box
               pt={10}
               pb={[0, 0, 25]} // [xs, sm, md]
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/bg-landing-page.png)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
               }}
            >
               <NavBar />
               <Box
                  sx={{
                     padding: { xs: 2, sm: 3, md: 10 },
                     textAlign: { xs: "center", md: "left" },
                  }}
               >
                  <Typography
                     variant="h4"
                     sx={{
                        color: "#162C44",
                        mb: 2,
                        fontSize: { xs: "1.5rem", md: "3.125rem" },
                     }}
                  >
                     Conozca las
                     <br />
                     <strong style={{ fontSize: "larger" }}>oportunidades de mejora</strong>
                     <br />
                     en la <strong>gobernanza</strong> de su organización
                  </Typography>

                  <Typography
                     variant="subtitle1"
                     sx={{
                        mb: 3,
                        fontSize: { xs: "0.875rem", md: "1.5rem" },
                     }}
                  >
                     Realice nuestro <strong>diagnóstico de gobernanza</strong> y obtenga una
                     <br />
                     <strong style={{ color: "#E8606D" }}>sesión de consultoría gratuita</strong> para fortalecer su
                     gobierno corporativo.
                  </Typography>
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        gap: 2,
                        alignItems: "center",
                        justifyContent: { xs: "center", md: "flex-start" },
                     }}
                  >
                     <TextField
                        label="Escriba su correo electrónico"
                        variant="filled"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                           width: { xs: "100%", md: "30%" },
                           "& .MuiOutlinedInput-root": {
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.4)",
                           },
                           "& .MuiFilledInput-root": {
                              "&:before": {
                                 borderBottom: "none",
                              },
                              "&:hover:not(.Mui-disabled):before": {
                                 borderBottom: "none",
                              },
                              "&:after": {
                                 borderBottom: "none",
                              },
                           },
                        }}
                     />

                     <Button
                        variant="contained"
                        onClick={handleNavigate}
                        sx={{
                           backgroundColor: "#162C44",
                           height: "fit-content",
                           width: { xs: "100%", md: "auto" },
                           fontSize: { xs: "0.875rem", md: "1rem" },
                           alignSelf: { xs: "center", md: "center" },
                        }}
                     >
                        Realizar diagnóstico
                     </Button>
                  </Box>

                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        alignItems: { xs: "center", md: "flex-start" },
                        mt: 2,
                     }}
                  >
                     <FormControlLabel
                        control={
                           <Checkbox
                              checked={accepted}
                              onChange={(e) => setAccepted(e.target.checked)}
                              sx={{
                                 color: "#162C44",
                                 "&.Mui-checked": {
                                    color: "#162C44",
                                 },
                              }}
                           />
                        }
                        label={
                           <>
                              He leído y acepto el{" "}
                              <Link
                                 href="https://lecosy.com.mx/Aviso-de-privacidad"
                                 target="_blank"
                                 rel="noopener noreferrer"
                                 sx={{
                                    color: "#162C44",
                                    textDecoration: "underline",
                                    fontSize: { xs: "0.75rem", md: "1rem" },
                                 }}
                              >
                                 Aviso de privacidad
                              </Link>
                           </>
                        }
                     />
                  </Box>
               </Box>
            </Box>

            <GridExample />

            <Box
               sx={{
                  width: "100%",
                  background:
                     "linear-gradient(180deg, white 50%, transparent 50%), linear-gradient(90deg, #000000 -40.94%, #162C44 13.92%)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: { xs: 3, md: 10 },
                  position: "relative",
                  flexDirection: { xs: "column", md: "row" },
                  gap: { xs: 2, md: 0 },
               }}
            >
               <Box
                  sx={{
                     position: "absolute",
                     top: 0,
                     width: "100%",
                     height: "50%",
                     backgroundImage:
                        "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/form-bg.svg)",
                     backgroundRepeat: "no-repeat",
                     backgroundPosition: "top",
                     backgroundSize: "contain",
                     zIndex: 1,
                  }}
               />

               <Box
                  sx={{
                     position: "relative",
                     width: "90%",
                     height: "90%",
                     overflow: "hidden",
                     borderRadius: 5,
                     zIndex: 2,
                     marginX: 10,
                  }}
               >
                  <Box
                     sx={{
                        background: "linear-gradient(83.31deg, #162C44 -24.27%, #000000 117.34%)",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 1,
                     }}
                  />

                  <Box
                     sx={{
                        backgroundImage:
                           "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/form-bg.svg)",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "bottom",
                        backgroundSize: "contain",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        bottom: 0,
                        zIndex: 2,
                     }}
                  />

                  <Box
                     sx={{
                        position: "relative",
                        zIndex: 3,
                        padding: { xs: 2, md: 4 },
                        borderRadius: 2,
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        alignItems: "center",
                        color: "white",
                     }}
                  >
                     <Box
                        sx={{
                           width: { xs: "100%", md: "50%" },
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                           mb: { xs: 2, md: 0 },
                        }}
                     >
                        <img
                           src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/landing-page/conocerMas.png"
                           alt=""
                           style={{
                              width: "100%",
                              maxWidth: "70%",
                              height: "auto",
                              borderRadius: "8px",
                           }}
                        />
                     </Box>
                     <Box
                        sx={{
                           width: { xs: "100%", md: "50%" },
                           textAlign: { xs: "center", md: "left" },
                           paddingX: { xs: 2, md: 5 },
                        }}
                     >
                        <Typography
                           variant="h5"
                           sx={{
                              mb: 0,
                              fontFamily: "regular",
                              fontSize: { xs: "1.25rem", md: "2rem" },
                           }}
                        >
                           ¿Quiere saber más sobre la{" "}
                        </Typography>
                        <Typography
                           variant="h5"
                           sx={{
                              mb: 0,
                              fontSize: { xs: "1.25rem", md: "3rem" },
                           }}
                        >
                           <strong>DIGITALIZACIÓN</strong>
                        </Typography>
                        <Typography
                           variant="h5"
                           sx={{
                              mb: 2,
                              fontFamily: "bold",
                              fontSize: { xs: "1.25rem", md: "2rem" },
                           }}
                        >
                           de Gobierno Corporativo?
                        </Typography>
                        <Typography
                           variant="body1"
                           sx={{
                              mb: 6,
                              fontFamily: "regular",
                              fontSize: { xs: "0.875rem", md: "1.2rem" },
                           }}
                        >
                           Explore nuestra <strong>solución en materia de gestión </strong> de gobernanza empresarial.
                        </Typography>
                        <Button
                           variant="contained"
                           sx={{
                              backgroundColor: "white",
                              color: "#162C44",
                              paddingX: 5,
                              paddingY: 1,
                              fontWeight: "bold",
                              "&:hover": {
                                 backgroundColor: "#f0f0f0",
                              },
                              width: { xs: "100%", md: "auto" },
                           }}
                           onClick={() => (window.location.href = "https://lecosy.com.mx/gobierno-corporativo")}
                        >
                           Conocer más
                        </Button>
                     </Box>
                  </Box>
               </Box>
            </Box>

            <Footer />
         </ScrollToTop>
      </ThemeConfig>
   );
};
