import React from "react";
import { Box, Typography } from "@mui/material";

/**
 * SummaryCard component to display a title and summary text.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.title - The title text to be displayed.
 * @param {string} props.summary - The summary text to be displayed.
 * @returns {JSX.Element} The rendered summary card component.
 */
const SummaryCard = ({ title, summary }) => (
  <Box sx={{ mb: { xs: 2, md: 3 } }}>
    <Typography
      sx={{ 
        mb: 1, 
        color: "#162C44", 
        textAlign: "justify", 
        fontWeight: "bold",  // Bold font weight
        fontSize: { xs: "24px", md: "32px" }  // Responsive font size
      }}
    >
      {title}
    </Typography>
    <Typography
      sx={{ 
        color: "#162C44", 
        textAlign: "justify", 
        fontSize: { xs: "16px", md: "18px" }  // Responsive font size
      }}
    >
      {summary}
    </Typography>
  </Box>
);

export default SummaryCard;
