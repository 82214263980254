import React, { createContext, useState, useContext } from 'react';

/**
 * Context for managing the email state.
 * @typedef {Object} EmailContextType
 * @property {string} email - The current email address.
 * @property {Function} setEmail - Function to update the email state.
 */

const EmailContext = createContext();

/**
 * Custom hook to access the email context.
 * 
 * @returns {EmailContextType} The context containing the email state and the function to update it.
 * @throws {Error} If the hook is used outside of an `EmailProvider`.
 */
export const useEmail = () => {
  const context = useContext(EmailContext);
  if (!context) {
    throw new Error('useEmail must be used within an EmailProvider');
  }
  return context;
};

/**
 * Provider component for the email context.
 * This component should wrap any part of the application that needs to access or modify the email state.
 * 
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to be wrapped by the context provider.
 * @returns {JSX.Element} The context provider that wraps the given children.
 */
export const EmailProvider = ({ children }) => {
  const [email, setEmail] = useState('');

  return (
    <EmailContext.Provider value={{ email, setEmail }}>
      {children}
    </EmailContext.Provider>
  );
};
